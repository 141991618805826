import React from 'react'
import '../App.css';
import checkIcon from '../images/checkIcon.svg'
import loadingIcon from '../images/loadingIcon.svg'

const PairGridAnswer = (props) => {
  const type = props.data.types[props.index];
  const correct = props.status === "correct"
  const incorrect = props.status === "incorrect"
  const finished = props.status === "finished"
  const selected = props.index === props.selection
  const state = incorrect ? "PairGridAnswer-incorrect" : correct ? "PairGridAnswer-correct" : selected ? "PairGridAnswer-selected" : finished ? "PairGridAnswer-finished" : "PairGridAnswer-default";


  function selectItem() {
    if (!finished) {
    props.selectItem(props.index)
    }
  }
  return (
    <div className="PairGridAnswer unselectable" onClick={selectItem}>
      <div className={type + state}>
        {props.answer === "Sent!" ? <img className="success-icon-visible" src={checkIcon} alt="checkmark icon" /> : null}
        {props.answer === "" ? <img className="loading-icon" src={loadingIcon} alt="loading icon" /> : null}
        {props.answer}
      </div>
    </div>  
  );
};

export default PairGridAnswer;