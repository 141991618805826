import React, {useState, useEffect} from 'react'
import Feedback from './Feedback.js'
import LabInstructions from '../lab/LabInstructions'
import DemoButton from './DemoButton'
import DemoInput from './DemoInput'
import DemoForm from './DemoForm'
import '../App.css';

const Demo = (props) => {
  const content = props.data;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [step, setStep] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [correctSubmission, setCorrectSubmission] = useState(false);
  const [instructions, setInstructions] = useState(true);

  function updateStep(newStep) {
    setStep(newStep);
  }
  function giveFeedback(newFeedback) {
    setFeedback(newFeedback);
  }
  function dismissFeedback() {
    setFeedback(null);
  }
  function submit() {
    giveFeedback(content.feedback[0]);
    setCorrectSubmission(true);
  }
  function dismissInstructions() {
    setInstructions(false);
  }
  function updateActivity() {
    setFeedback(null);
    setCorrectSubmission(false);
    props.nextStep();
  }

  return (
    <div className="App">
      {instructions 
        ? <LabInstructions 
            data={content}
            step={step}
            activity={props.activity}
            lab={true}
            />
        : null}
      {content.object === "input" 
        ? 
          <DemoInput 
            data={content}
            dismissInstructions={dismissInstructions} 
            submit={submit}
            dismissFeedback={dismissFeedback}
            updateStep={updateStep}
          />
        : content.object === "button" 
        ? <DemoButton 
            dismissInstructions={dismissInstructions} 
            submit={submit}
            dismissFeedback={dismissFeedback}
            updateStep={updateStep}
            icon={props.icon}
            animation={props.animation}
            customized={content.customized}
          />
        : <DemoForm 
            dismissInstructions={dismissInstructions} 
            submit={submit}
            dismissFeedback={dismissFeedback}
            updateStep={updateStep}
            icon={props.icon}
            animation={props.animation}
            messages={props.messages}
            customized={content.customized}
          />}
      {feedback ? 
        <Feedback 
          feedback={feedback}
          dismissFeedback={dismissFeedback} 
          correctSubmission={correctSubmission}
          updateActivity={updateActivity}
          data={content}
        />
      : null}
    </div>
  );
}

export default Demo;
