import React, {useEffect} from 'react'
import welcome from '../images/welcome.svg'
import principles from '../images/principles2.svg'
import hierarchyMain from '../images/hierarchyMain.png'
import typogFive from '../images/typography5.jpg'
import wotfard from '../images/wotfard.png'
import typoHierList from '../images/typoHierList.svg'
import typoHierHeadingSize from '../images/typoHierHeadingSize.svg'
import typoHierSubheadingSize from '../images/typoHierSubheadingSize.svg'
import typoHierTextSize from '../images/typoHierText.svg'
import buttonsIntro from '../images/buttonsIntro.png'
import btnHierarchy from '../images/btnHierarchy.svg'
import btnSize from '../images/btnSize.svg'
import btnContrast from '../images/btnContrast.svg'
import marginPadding from '../images/marginPadding.svg'
import ActivityTitle from './ActivityTitle'
import ActivitySubtitle from './ActivitySubtitle'
import '../App.css';

const Statement = (props) => {
  const content = props.data;
  const images = [[null, welcome], [null, principles, hierarchyMain], [null, null, null, typogFive, null, null], [null, null, null], [null, buttonsIntro, null, null, marginPadding], [null]]
  const secondaryImages = [null, null, [null, wotfard], [null, typoHierList, typoHierHeadingSize, typoHierSubheadingSize, typoHierTextSize], [null, btnSize, btnHierarchy, btnContrast]];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="Statement">
      <div className="Activity-content">
        <div className="Activity-info">
          {images[props.activity][props.step] ? <img className="Activity-image Statement-image" src={images[props.activity][props.step]} alt={props.data.alt} /> : null}
          <ActivityTitle data={props.data} />
          <ActivitySubtitle data={props.data} />
          {content.secondaryImage ? <img className="Activity-image Statement-secondary-image" src={secondaryImages[props.activity][content.secondaryImage]} alt={props.data.alt} /> : null}
        </div>
      </div>
      <div className="Activity-btn-container">
        <div className="Activity-primary-btn" onClick={props.nextStep}>{content.buttonText}</div>
      </div>
    </div>
  );
}; 

export default Statement;