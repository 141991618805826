import React from 'react';
import '../App.css';

const LabArtboardButton = (props) => {
  const content = props.data;
  const radius = props.radius + "px";

  return (
      <div 
        className={"Object Object-edit " + props.fill + " " + props.stroke + " " + props.shadow} 
        style={{borderRadius: radius}}
        onClick={props.updateStep}
      >
        {content.animations 
          ? <div class={"container animation-" + props.animate}>
              <div class="shape shape1"></div>
              <div class="shape shape2"></div>
              <div class="shape shape3"></div>
              <div class="shape shape4"></div>
            </div>
          : null
        }
        {content.icons && props.icon !== null
          ? <img className="LabArtboard-button-icon" src={props.icons[props.icon]} alt="UI icon" />
          : null}
        {content.content[0]}
      </div>
  );
}

export default LabArtboardButton;
