import React, {useState, useEffect} from 'react'
import LabInstructions from './LabInstructions.js';
import LabArtboard from './LabArtboard.js'
import LabToolbar from './LabToolbar.js'
import LabFill from './LabFill.js'
import LabStroke from './LabStroke.js'
import LabSlider from './LabSlider.js'
import Shadow from '../components/Shadow.js'
import LabIcon from './LabIcon'
import LabAnimate from './LabAnimate'
import LabAlign from './LabAlign'
import Feedback from '../components/Feedback.js'
import LabPracticeSubmit from './LabPracticeSubmit'
import '../App.css';

const LabPractice = (props) => {
  const content = props.data;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const [step, setStep] = useState(content.step);
  const [tool, setTool] = useState(null);
  const [fill, setFill] = useState(content.fill);
  const [stroke, setStroke] = useState(content.stroke);
  const [radius, setRadius] = useState(content.radius);
  const [scale, setScale] = useState([100, 100, 100, 100, 100]);
  const [margin, setMargin] = useState([16, 16, 16, 16]);
  const [hPadding, setHPadding] = useState(0);
  const [vPadding, setVPadding] = useState(0);
  const [shadow, setShadow] = useState(content.shadow);
  const [showPrompt, setShowPrompt] = useState(false);
  const [correctSubmission, setCorrectSubmission] = useState(false);
  const [fontSize, setFontSize] = useState(content.fontSize)
  const [fontWeight, setFontWeight] = useState([400, 400, 400])
  const [selection, setSelection] = useState(null);
  const [fontColor, setFontColor] = useState([content.fontColor]);
  const [animate, setAnimate] = useState(null);
  const [align, setAlign] = useState(null);
  const [icon, setIcon] = useState(null);
  const [y, setY] = useState(0);

  function updateStep(newStep) {
    setStep(newStep);
  }
  function showTool(currentTool) {
    if (showPrompt === true) {
      setShowPrompt(false);
    }
    setTool(currentTool);
  }
  function updateFill(newFill) {
    setFill(newFill);
  }
  function updateStroke(newStroke) {
    setStroke(newStroke);
  }
  function updateRadius(newRadius) {
    setRadius(newRadius);
  }
  function updateScale(item, newScale) {
    const scales = scale.slice();
    scales[item] = newScale
    setScale(scales);
  }
  function updateMargin(item, newMargin) {
    const margins = margin.slice();
    margins[item] = newMargin;
    setMargin(margins);
  }
  function updateHPadding(newHPadding) {
    setHPadding(newHPadding);
  }
  function updateVPadding(newVPadding) {
    setVPadding(newVPadding);
  }
  function updateY(newY) {
    setY(newY);
  }
  function updateShadow(toggleShadow) {
    setShadow(toggleShadow);
  }
  function updateFontSize(item, size) {
    const sizes = fontSize.slice();
    sizes[item] = size;
    setFontSize(sizes);
  }
  function updateFontWeight(item, weight) {
    const weights = fontWeight.slice();
    weights[item] = weight;
    setFontWeight(weights);
  }
  function updateFontColor(item, color) {
    const colors = fontColor.slice();
    colors[item] = color;
    setFontColor(colors);
  }
  function updateAnimate(newAnimate) {
    setAnimate(newAnimate);
    props.updateStoredAnimate(newAnimate);
  }
  function updateAlign(newAlign) {
    setAlign(newAlign);
  }
  function updateIcon(newIcon) {
    setIcon(newIcon);
    props.updateStoredIcon(newIcon);
  }
  function displayPrompt() {
    setShowPrompt(true);
  }
  function selectItem(item) {
    setSelection(item)
    setTool(null)
  }

  function updateActivity() {
    props.dismissFeedback();
    setCorrectSubmission(false);
    props.nextStep();
  }

  return (
    <div className="App">
      <LabInstructions 
        step={step}
        activity={props.activity}
        data={content}
        />
      <LabArtboard 
        data={props.data}
        step={step}
        fill={fill}
        stroke={stroke}
        scale={scale}
        margin={margin}
        hPadding={hPadding}
        vPadding={vPadding}
        radius={radius}
        shadow={shadow}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontColor={fontColor}
        animate={animate}
        icon={icon}
        align={align}
        y={y}
        storedMessages={props.storedMessages}
        updateStep={updateStep}
        activity={props.activity}
        displayPrompt={displayPrompt}
        selectItem={selectItem}
        selection={selection}
        />
      {showPrompt ? <div className="prompt">Use these tools to change the styles</div> : null}
      {tool === 'Fill' || tool === 'Color' ? 
        <LabFill 
          data={props.data}
          selection={selection}
          onChange={updateFill}
          updateFontColor={updateFontColor}
          activity={props.activity}
          fill={fill}
          tool={tool}
          fontColor={fontColor} />
      : tool === 'Stroke' ?
        <LabStroke 
          data={props.data}
          onChange={updateStroke}
          activity={props.activity}
          stroke={stroke} />
      : tool === 'Radius' || tool === 'Scale' || tool === 'Margin' || tool === 'Padding' || tool === 'Size' || tool === 'Weight' || tool === 'MoveY' ?
        <LabSlider 
          tool={tool}
          selection={selection}
          updateRadius={updateRadius}
          updateScale={updateScale}
          updateMargin={updateMargin}
          updateHPadding={updateHPadding}
          updateVPadding={updateVPadding}
          updateFontSize={updateFontSize}
          updateFontWeight={updateFontWeight}
          updateY={updateY}
          radius={radius}
          scale={scale}
          y={y}
          margin={margin}
          vPadding={vPadding}
          hPadding={hPadding}
          fontSize={fontSize}
          fontWeight={fontWeight} />
      : tool === 'Shadow' ?
        <Shadow   
          onChange={updateShadow}
          shadow={shadow} 
          activity={props.activity} />
      : tool === 'Icon' ? 
        <LabIcon 
          data={props.data}
          selection={selection}
          updateIcon={updateIcon}
          activity={props.activity}
          icon={icon} />
      : tool === 'Animate' ? 
        <LabAnimate 
          data={props.data}
          selection={selection}
          updateAnimate={updateAnimate}
          activity={props.activity}
          animate={animate} />
      : tool === 'Align' ? 
        <LabAlign
          data={props.data}
          selection={selection}
          updateAlign={updateAlign}
          activity={props.activity}
          align={align} />
      : null} 
      {props.feedback ? 
        <Feedback 
          feedback={props.feedback}
          dismissFeedback={props.dismissFeedback} 
          correctSubmission={correctSubmission}
          updateActivity={updateActivity}
          data={content}
        />
      : null}
      <LabToolbar
        data={content} 
        activity={props.activity}
        onChange={showTool} 
        submit={props.submit}
        step={step} 
        selection={selection}
      />
      <LabPracticeSubmit 
        data={content}
        feedback={props.feedback}
        section={props.section}
        activity={props.activity}
        step={step}
        fill={fill}
        stroke={stroke}
        scale={scale}
        margin={margin}
        hPadding={hPadding}
        vPadding={vPadding}
        radius={radius}
        shadow={shadow}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontColor={fontColor}
        animate={animate}
        y={y}
        icon={icon}
        align={align}
        giveFeedback={props.giveFeedback}
        setCorrectSubmission={setCorrectSubmission}
      />
    </div>
  );
}

export default LabPractice;
