import React from 'react';
import '../App.css';

function Shadow(props) {

  function updateShadowSwipe(event) {
    event.preventDefault();
    if (props.shadow === null && props.activity === "activity2") {
      props.onChange('gray-shadow');
    }
    else if (props.shadow === null) {
      props.onChange('purple-shadow');
    }
    else {
      props.onChange(null);
    }
  }
  function updateShadow() {
    if (props.shadow === null && props.activity === "activity2") {
      props.onChange('gray-shadow');
    }
    else if (props.shadow === null) {
      props.onChange('purple-shadow');
    }
    else {
      props.onChange(null);
    }
  }

  return (
    <div className="Shadow">
      <div className="toggle-container" onTouchEnd={updateShadowSwipe} onClick={updateShadow}>
        <div className={props.shadow ? "toggle-btn toggle-btn-on" : "toggle-btn"}></div>
        <div className={props.shadow ? "toggle-bg toggle-bg-on" : "toggle-bg"}></div>
      </div>
    </div>
  );
}

export default Shadow;
