import React from 'react';
import '../App.css';

const LabArtboardForm = (props) => {
  const content = props.data;
  const fontSize = props.fontSize + "px";
  const redLabel = props.stroke === 'red-border' ? " red-text" : null;

  return (
    <div onClick={props.updateStep}>
      <div className={content.selected[0]}>
        <div className={"Object Object-edit-input justify-left white r-8 " + props.stroke}>
          {content.content[0]}
          <div className="LabArtboardInput-line LabArtboardInput-line-expand"></div>
          <p className={"LabArtboardInput-label " + redLabel}>Email</p>
        </div>
      </div>
      <p 
        className={props.step === 1 ? ("LabArtboardForm-message " + content.selected[1]) : "LabArtboardForm-message"} 
        style={{
          color: props.fontColor,
          fontSize: fontSize}}>
          {props.storedMessages[0]}
      </p>
      <div className="Object Object-edit-button purple r-8 no-border">
          {content.content[1]}
      </div>
    </div>
  );
}

export default LabArtboardForm;
