import React, {useState} from 'react'
import ActivityTitle from './ActivityTitle'
import ActivitySubtitle from './ActivitySubtitle'
import MultiChoiceGrid from './MultiChoiceGrid'
import hierarchyChangeZero from '../images/hierarchyChangeZero.svg'
import hierarchyChangeOne from '../images/hierarchyChange1.svg'
import hierarchyChangeTwoA from '../images/hierarchyChange2a.svg'
import hierarchyChangeThree from '../images/hierarchyChange3.svg'
import hierarchyChangeFour from '../images/hierarchyChange4.svg'
import typoPractice from '../images/typoPractice.jpg'
import typoPlaceholder from '../images/typoPlaceholder.svg'
import socialGothic from '../images/socialGothic.svg'
import americanTypewriter from '../images/ameriTypewriter.svg'
import cubano from '../images/cubano.svg'
import stix from '../images/Stix.svg'
import shop from '../images/shop.svg'
import '../App.css';


const StyleChange = (props) => {
  const content = props.data;
  const styleOptions = ["StyleChange-heading1", "StyleChange-heading2", "StyleChange-heading3", "StyleChange-heading4"];
  const [styles, setStyles] = useState([null, null, null, null]);
  const [hierStyles, setHierStyles] = useState(["", ["", ""], ["", "", ""], ["", ""], ""]);
  const [typeHierStyle, setTypeHierStyle] = useState("15px");
  const [selections, setSelections] = useState([false, false, false, false]);
  const [spacing, setSpacing] = useState([0, 0, 0, 0]);
  const [answer, setAnswer] = useState(0);
  const [font, setFont] = useState(typoPlaceholder);
  const fonts = [socialGothic, americanTypewriter, cubano, stix];

  function updateStyle(style) {

    const newSelections = selections.slice();
    function addSelection() {
      newSelections[style] = style;
      setSelections(newSelections)
    }
    function removeSelection() {
      newSelections[style] = false;
      setSelections(newSelections)
    }

    // If this is the hierarchy question
    if (props.activity === 1) {
      const newHierStyles = hierStyles.slice();

      switch(style) {

      // Update Spacing
      case 0:
        if (selections[style] === false) {
          newHierStyles[0] = " objectOneOne-content-fix"
          newHierStyles[1][0] = " StyleChange-img-one-spacing-fix"
          newHierStyles[3][0] = " StyleChange-img-three-spacing-fix"
          setHierStyles(newHierStyles);
          addSelection();
        }
        else {
          newHierStyles[0] = "";
          newHierStyles[1][0] = "";
          newHierStyles[3][0] = "";
          setHierStyles(newHierStyles)
          removeSelection();
        }
        break

        // Update Size
        case 1:
          if (selections[style] === false) {
            newHierStyles[1][1] = " StyleChange-img-one-size-fix"
            newHierStyles[2][0] = " objectOneOne-size-fix"
            newHierStyles[3][1] = " StyleChange-img-three-size-fix"
            setHierStyles(newHierStyles);
            addSelection();
          }
          else {
            newHierStyles[1][1] = "";
            newHierStyles[2][0] = "";
            newHierStyles[3][1] = "";
            setHierStyles(newHierStyles)
            removeSelection();
          }
          break

        // Update Value
        case 2:
        if (selections[style] === false) {
          newHierStyles[1][2] = " StyleChange-img-one-depth-fix"
          newHierStyles[2][2] = " objectOneOne-depth-fix"
          newHierStyles[3][2] = " StyleChange-img-three-depth-fix"
          newHierStyles[4] = " StyleChange-img-four-depth-fix"
          setHierStyles(newHierStyles);
          addSelection();
        }
        else {
          newHierStyles[1][2] = "";
          newHierStyles[2][2] = "";
          newHierStyles[3][2] = "";
          newHierStyles[4] = "";
          setHierStyles(newHierStyles)
          removeSelection();
        }
        break


        // Update Color
        default: 
          if (selections[style] === false) {
            newHierStyles[2][1] = " objectOneOne-color-fix"
            setHierStyles(newHierStyles);
            addSelection();
          }
          else {
            newHierStyles[2][1] = "";
            setHierStyles(newHierStyles)
            removeSelection();
          }
      }
    }


    // If this is the first typography hierarchy question
    else if (props.activity === 2) {

      switch(style) {
        case 0:
          setFont(fonts[style]);
          setSelections([0, false, false, false]);
          setAnswer(style);
        break

        case 1:
          setFont(fonts[style]);
          setSelections([false, 1, false, false]);
          setAnswer(style);
        break

        case 2:
          setFont(fonts[style]);
          setSelections([false, false, 2, false]);
          setAnswer(style);
        break

        default:
          setFont(fonts[style]);
          setSelections([false, false, false, 3]);
          setAnswer(style);
      }

    }


    // If this is the first typography hierarchy question
    else if (props.activity === 3 && props.step === 4) {
      const newStyles = styles.slice();

      if (styles[style] === null) {
        newStyles[style] = styleOptions[style];
        setStyles(newStyles);
        addSelection();
      }
      else {
        newStyles[style] = null;
        setStyles(newStyles)
        removeSelection();
      }
    }

    // If this is the hierarchy heading question
    else if (props.activity === 3 && props.step === 6) {
      switch(style) {
        case 0:
          setTypeHierStyle("12px");
          setSelections([0, false, false, false]);
          setAnswer(style);
        break

        case 1:
          setTypeHierStyle("20px");
          setSelections([false, 1, false, false]);
          setAnswer(style);
        break

        case 2:
          setTypeHierStyle("32px");
          setSelections([false, false, 2, false]);
          setAnswer(style);
        break

        default:
          setTypeHierStyle("54px");
          setSelections([false, false, false, 3]);
          setAnswer(style);
      }
    }    
    
    // If this is the hierarchy subheading question
    else if (props.activity === 3 && props.step === 9) {
      switch(style) {
        case 0:
          setTypeHierStyle("14px");
          setSelections([0, false, false, false]);
          setAnswer(style);
        break

        case 1:
          setTypeHierStyle("24px");
          setSelections([false, 1, false, false]);
          setAnswer(style);
        break

        case 2:
          setTypeHierStyle("30px");
          setSelections([false, false, 2, false]);
          setAnswer(style);
        break

        default:
          setTypeHierStyle("32px");
          setSelections([false, false, false, 3]);
          setAnswer(style);
      }
    }

        // If this is the hierarchy body text question
        else if (props.activity === 3 && props.step === 11) {
          switch(style) {
            case 0:
              setTypeHierStyle("10px");
              setSelections([0, false, false, false]);
              setAnswer(style);
            break
    
            case 1:
              setTypeHierStyle("13px");
              setSelections([false, 1, false, false]);
              setAnswer(style);
            break
    
            case 2:
              setTypeHierStyle("16px");
              setSelections([false, false, 2, false]);
              setAnswer(style);
            break
    
            default:
              setTypeHierStyle("25px");
              setSelections([false, false, false, 3]);
              setAnswer(style);
          }
        }

        // If this is the button spacing question
        else if (props.activity === 4) {
          const currentSpacing = spacing.slice()
          switch(style) {
            case 0:
              if (selections[style] === false) {
                currentSpacing[0] = "auto"
                setSpacing(currentSpacing)
                addSelection();
              }
              else {
                currentSpacing[0] = 0
                setSpacing(currentSpacing)
                removeSelection();
              }
            break
    
            case 1:
              if (selections[style] === false) {
                currentSpacing[1] = "24px"
                setSpacing(currentSpacing)
                addSelection();
              }
              else {
                currentSpacing[1] = 0
                setSpacing(currentSpacing)
                removeSelection();
              }
            break
    
            case 2:
              if (selections[style] === false) {
                currentSpacing[2] = "22px"
                setSpacing(currentSpacing)
                addSelection();
              }
              else {
                currentSpacing[2] = 0
                setSpacing(currentSpacing)
                removeSelection();
              }
            break
    
            default:
              if (selections[style] === false) {
                currentSpacing[3] = "16px"
                setSpacing(currentSpacing)
                addSelection();
              }
              else {
                currentSpacing[3] = 0
                setSpacing(currentSpacing)
                removeSelection();
              }
          }
        }
  }
  function submitAnswer() {
    props.submitAnswer(answer);
  }
  return (
    <div className="Question">      
      <div className="Activity-content">
        <div className="Activity-info">
          <ActivityTitle data={props.data} />
          <ActivitySubtitle data={props.data} />
          {props.activity === 1 
            ? <div className="StyleChange-object-container objectOneOne">
                <img className="StyleChange-img-zero" src={hierarchyChangeZero} alt="UI element" />
                <div className={"objectOneOne-content" + hierStyles[0]}>
                  <img className={"StyleChange-img-one" + (hierStyles[1].join(""))} src={hierarchyChangeOne} alt="UI element"  />
                  <div className="objectOneOne-content-item">
                    <div className={"objectOneOne-content-item-container" + (hierStyles[2].join(""))}>
                      <img className="StyleChange-img-two" src={hierarchyChangeTwoA} alt="UI element"  />
                    </div>
                    <img className={"StyleChange-img-four" + hierStyles[4]} src={hierarchyChangeFour} alt="UI element"  />
                  </div>
                  <img className={"StyleChange-img-three" + (hierStyles[3].join(""))} src={hierarchyChangeThree} alt="UI element"  />
                </div>
              </div>
            : (props.activity === 2) 
            ?  <div className="StyleChange-object-container">
                <div className="StyleChange-image-container">
                  <img className="StyleChange-image objectOneTwo" src={typoPractice} alt="Skateboard website" />
                  <img className="StyleChange-image objectText" src={font} alt="heading" />
                </div>
              </div>
            : (props.activity === 3 && props.step === 4) 
            ?  <div className="StyleChange-object-container">
                <div className="StyleChange-object">
                  <p className={"StyleChange-heading " + styles.join(" ")}>
                    I'm a heading
                  </p>
                  <p className="StyleChange-text">I'm a short paragraph of body text. I'm only a couple of sentences long.</p>
                </div>
              </div>
            : (props.activity === 3 && props.step === 6)
            ? <div className="StyleChange-object-container StyleChange-object-container-two">
                <div className="StyleChange-object">
                  <p className="StyleChange-heading-resize" style={{fontSize: typeHierStyle}}>
                    I'm a heading
                  </p>
                  <p className="StyleChange-text">This body text is 16pt. We'll discuss what "pt" stands for later.</p>
                </div>
              </div>
            : (props.activity === 3 && props.step === 9)
            ? <div className="StyleChange-object-container StyleChange-object-container-two">
                <div className="StyleChange-object">
                  <p className="StyleChange-heading-resize" style={{fontSize: "32px"}}>
                    I'm a heading
                  </p>
                  <p className="StyleChange-subheading-resize" style={{fontSize: typeHierStyle}}>
                    I'm a subheading
                  </p>
                  <p className="StyleChange-text">This body text is 16pt. We'll discuss what "pt" stands for later.</p>
                </div>
              </div>
            : (props.activity === 3 && props.step === 11) 
            ? <div className="StyleChange-object-container StyleChange-object-container-three">
                <div className="StyleChange-object">
                  <p className="StyleChange-heading-resize" style={{fontSize: "32px"}}>
                    How to Grow 
                  </p>
                  <p className="StyleChange-subheading-resize" style={{fontSize: "24px"}}>
                    A quick guide
                  </p>
                  <p className="StyleChange-text-resize" style={{fontSize: typeHierStyle}}>
                    Do what you enjoy. Mastering a skill takes time. It’s much easier if it’s something you enjoy doing.
                  </p>
                </div>
              </div>
            : (props.activity === 4) 
            ? <div className="StyleChange-object-container objectFiveOne">
                <h2 className="StyleChange-h2">Buy all the things!</h2>
                <div className="StyleChange-button2" 
                      style={{
                        marginLeft: spacing[0], 
                        marginRight: spacing[0], 
                        marginTop: spacing[1],
                        marginBottom: spacing[1],
                        paddingLeft: spacing[2],
                        paddingRight: spacing[2],
                        paddingTop: spacing[3],
                        paddingBottom: spacing[3]}}>
                  <img className="StyleChange-button-icon" src={shop} alt="Shopping bag" />
                  <p className="StyleChange-button-text">Start Shopping</p>
                </div>
              </div>
          : null}
          <MultiChoiceGrid 
            data={props.data} 
            submitAnswer={updateStyle}
            selection={selections}
          />
        </div>
      </div>
      <div className="Activity-btn-container">
        <div className="Activity-primary-btn" onClick={submitAnswer}>{content.buttonText}</div>
      </div>
    </div>
  );
};

export default StyleChange;