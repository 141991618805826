import React from 'react';
import slash from '../images/slash.svg';
import '../App.css';

function FillColor(props) {
  function updateFill() {
    if (props.activity === 3) {
      props.updateFontColor(props.selection, props.hex)
    } 
    else {
      props.updateFill(props.color)
    }

  }
  let colorIcon = (props.color === "no-color") ? "no-color-icon" : (props.color === "white") ? "white-icon" : props.color;
  return (
    <div className={(props.color === props.fill && props.activity !== 3) || (props.fontColor === props.hex) ? "selected" : null}>
      <div 
        className={"Color " + colorIcon}
        onClick={updateFill}
        >
        {props.color === "no-color" ? <img src={slash} alt="" /> : null}
      </div>
    </div>
  );
}

export default FillColor;
