import React from 'react';
import LabArtboardButton from './LabArtboardButton'
import LabArtboardInput from './LabArtboardInput'
import LabArtboardForm from './LabArtboardForm'
import sparkle from '../icons/sparkleIcon.svg'
import search from '../icons/searchIcon.svg'
import lightning from '../icons/lightingIcon.svg'
import check from '../icons/checkIcon.svg'
import checkCircle from '../icons/checkCircleIcon.svg'
import camera from '../icons/cameraIcon.svg'
import '../App.css';

const icons = [sparkle, search, lightning, check, checkCircle, camera];

const Artboard = (props) => {
  const content = props.data;
  const object = content.object
  function updateStep() {
    if (props.step === 0) {
      props.updateStep(1);
      props.displayPrompt();
    }
    if (content.selectItem) {
      props.selectItem(0);
    }
  }
  return (
    <div>
      {object === "button"
        ? <div className="Object-container">
            <LabArtboardButton 
              icons={icons}
              updateStep={updateStep}
              data={content}
              radius={props.radius}
              fill={props.fill}
              stroke={props.stroke}
              shadow={props.shadow}
              animate={props.animate}
              icon={props.icon}
            />
          </div>
        : object === "input" 
        ? <div className="Object-container">
            <LabArtboardInput 
              updateStep={updateStep}
              data={content}
              radius={props.radius}
              fill={props.fill}
              stroke={props.stroke}
              align={props.align}
              step={props.step}
              y={props.y}
              fontSize={props.fontSize}
              fontColor={props.fontColor}
              selection={props.selection}
            />
          </div>
        : <div className="LabArtboardForm">  
            <LabArtboardForm
              icons={icons}
              updateStep={updateStep}
              data={content}
              stroke={props.stroke}
              step={props.step}
              storedMessages={props.storedMessages}
              fontSize={props.fontSize}
              fontColor={props.fontColor}
              selection={props.selection}
            />
          </div>
      }
    </div>
  );
}

export default Artboard;
