import React, {useEffect} from 'react'
import inputExplain1 from '../images/inputExplain.svg'
import inputExplain2 from '../images/inputExplain2.svg'
import inputExplain3 from '../images/inputExplain3.svg'
import inputExplain4 from '../images/inputExplain4.svg'
import formExample from '../images/formExample.svg'
import errorExample2 from '../images/errorExample2.svg'
import errorExample3 from '../images/errorExample3.svg'
import '../App.css';

const images = {2.3: [null, inputExplain1, null, inputExplain2],
                2.4: [null, inputExplain3, inputExplain4],
                3.1: [null, formExample],
                3.5: [null, errorExample2, errorExample3]
              }

const LabStatement = (props) => {
  const data = props.data;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="Statement">
      <div className="Activity-content">
        <div className="Activity-info">
          {data.content.map((item, index) => {
            return item === "image" 
              ? <img className="LabStatement-image" src={images[data.id][index]} alt="" />
              : <p className="LabStatement-text">{item}</p>
          })}
        </div>
      </div>
      <div className="Activity-btn-container">
        <div className="Activity-primary-btn" onClick={props.nextStep}>{data.buttonText}</div>
      </div>
    </div>
  );
}; 

export default LabStatement;