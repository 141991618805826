import React from 'react';
import LabAnimateOptions from './LabAnimateOptions.js'
import '../App.css';

function LabAnimate(props) {

  return (
    <div className="Fill Fill-dark">
      <div className="Fill-row space-around">
        {props.data.animations.map((animation, index) => {
          return <LabAnimateOptions
                    data={props.data}
                    activity={props.activity}
                    index={index}
                    selection={props.selection}
                    animation={animation}
                    animate={props.animate}
                    updateAnimate={props.updateAnimate}
                  />
            })}
      </div>
    </div>
  );
}

export default LabAnimate;
