import React from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import '../App.css';
import close from '../images/close.svg';
import back from '../images/back.svg';

function LabProgress(props) {
  const activities = props.data;
  const barLength = {
    width: 100 * (props.step) / activities.length + '%'
  };

  function finishActivity() {
    props.updateProgress();
    props.closeActivity();
  }
  return (
      <div className="Progress-container">    
        {props.step === 0 
          ? <Link to={"/lab"}>
              <div className="Progress-icon-container">
                <img className="Progress-icon-back" src={back} alt="back button" onClick={props.step === 0 ? props.closeActivity : props.goBack} />
              </div>
            </Link>
          : <div className="Progress-icon-container">
              <img className="Progress-icon-back" src={back} alt="back button" onClick={props.step === 0 ? props.closeActivity : props.goBack} />
            </div>
        }  

        <div className="Progress-bg">
          <div className="Progress-bar purple" style={barLength}></div>
        </div>
        <Link to={"/lab"}>
          <div className="Progress-icon-container">
            <img className="Progress-icon-close" src={close} alt="close button" onClick={activities.length === props.step ? finishActivity : props.closeActivity} />
          </div>
        </Link>
        <Router></Router>
      </div>
  );
}

export default LabProgress;
