import React from 'react';
import LabExample from './LabExample.js';
import '../App.css';

function LabInstructions(props) {
  const content = props.data
  const text = content.text;
  const text2 = content.text2;
  return (

    <>
      <div className="Instructions-container" onClick={props.testSubmit}>
        <div className={props.step > 0 ? "Instructions-row Instructions-slideout" : "Instructions-row"}>
          <div className="Instructions">
            <div className="Instructions-content">
              <p className="Instructions-content-text">{text[props.step]}</p>
              <p className="Instructions-content-text">{text2[props.step]}</p>
            </div>
          </div>
          <div className="Instructions">
            <div className="Instructions-content">
              <p className="Instructions-content-text">{text[props.step]}</p>
              <p className="Instructions-content-text">{text2[props.step]}</p>
              {content.image 
                ? <div className={props.step > 0 ? "Instructions-content-example" : "Instructions-content-example gone"}>
                    <LabExample
                      activity={props.activity}
                      data={props.data} />
                  </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LabInstructions;
