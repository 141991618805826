import React, {useState} from 'react'
import '../App.css';
import '../animations.css'
import sparkle from '../icons/sparkleIcon.svg'
import search from '../icons/searchIcon.svg'
import lightning from '../icons/lightingIcon.svg'
import check from '../icons/checkIcon.svg'
import checkCircle from '../icons/checkCircleIcon.svg'
import camera from '../icons/cameraIcon.svg'

const icons = [sparkle, search, lightning, check, checkCircle, camera];

const DemoForm = (props) => {
  const [interactionState, setInteractionState] = useState("Default");
  const [inputValue, setInputValue] = useState(null);
  const [inputState, setInputState] = useState("Default");
  const errorText = (inputState === "error1") || (inputState === "error2") ? "red-text" : null;
  const hasValue = (inputValue !== null) && (inputValue !== "");
  const icon = (props.icon === null) ? 2 : props.icon
  const animation = (props.animation === null) ? 4 : props.animation
  const messages = (props.messages[0] === null) ? ["Oops, please add an email", "Please make sure your email is correct"] : props.messages
  const objectStyle = props.customized ? ["Object DemoForm-button Example purple no-border purple-shadow r-8", "Object DemoForm-button Example purple-dark no-border r-8", "Object DemoForm-button Example purple no-border r-8", "Object DemoForm-button Example purple no-border r-8"] : ["Object DemoForm-button Example salmon no-border salmon-shadow r-8", "Object DemoForm-button Example salmon-dark no-border r-8", "Object DemoForm-button Example salmon no-border r-8", "Object DemoForm-button Example salmon no-border r-8"];
  
  const defaulted = (interactionState === "Default");
  const success = (interactionState === "Success");
  const pressed = (interactionState === "Pressed");
  const loading = (interactionState === "Loading");

  function pressedState() {
    if (defaulted) {
      setInteractionState("Pressed")
      props.dismissInstructions();
      props.dismissFeedback();
    }
  }

  function checkInput() {
    if (!inputValue) {
      setInputState("error1");
      setInteractionState("Default")
    }
    else if ( /(.+)@(.+){2,}\.(.+){2,}/.test(inputValue)) {
      loadingState()
    } else {
      setInputState("error2");
      setInteractionState("Default")
    }

  }

  function loadingState() {
    if (pressed || defaulted) {
    setInputState("Defualt")
    setInteractionState("Loading");
    setTimeout(successState, 3000);
    }
  }

  function successState() {
    setInteractionState("Success");
    setTimeout(defaultState, 2000);
  }

  function defaultState() {
    setInteractionState("Default");
    props.submit();
  }

  function handleInputChange(e) {
    setInputValue(e.target.value);
  }

  return (
    <div className="DemoForm">

      {/* Text Field */}
      <input className={(inputState === "error1") || (inputState === "error2") ? "DemoForm-input DemoForm-input-error" : "DemoForm-input"} type="email" onChange={handleInputChange} />
      <div className={hasValue ? "DemoForm-input-line DemoForm-input-line-expanded" : "DemoForm-input-line"}></div>
      <p className={hasValue ? ("DemoForm-input-label DemoForm-input-label-raised " + errorText) : "DemoForm-input-label"}>Email</p>
      <div className="DemoForm-input-error-container">
        <p className={"DemoForm-input-error-message"}>{inputState === "error1" ? messages[0] : inputState === "error2" ? messages[1] : null}</p>
      </div>


      {/* Button */}

      <div className={interactionState === "Pressed" ? objectStyle[1] : interactionState === "Loading" ? objectStyle[2] : interactionState === "Success" ? objectStyle[3] : objectStyle[0]} onTouchStart={pressedState} onMouseDown={pressedState} onTouchEnd={checkInput} onMouseUp={checkInput}>
        {loading
          ? <div class={"container animation-" + animation}>
              <div class="shape shape1"></div>
              <div class="shape shape2"></div>
              <div class="shape shape3"></div>
              <div class="shape shape4"></div>
            </div>
          : null
        }
        <div className="success-message">
          <img className={success ? "success success-icon-visible unselectable" : "success success-icon-invisible unselectable"} src={icons[icon]} alt="checkmark icon" />
          <p className={success || defaulted ? "success message-visible unselectable" : pressed ? "message-visible unselectable" : "success message-invisible unselectable"}>{success ? "Subscribed!" : "Subscribe"}</p>
        </div>
      </div>

    </div>
  );
}

export default DemoForm;
