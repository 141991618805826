import React from 'react';
import LabFillColor from './LabFillColor.js'
import '../App.css';

const hexes1 = ["rgba(0,0,0,0)", "#fff", "#c5cfd8", "#7B8895", "#4E5C6A", "#000", "#3C7669"]
const hexes2 = ["#F0C44C", "#DB874A", "#C4505E", "#AE46CA", "#6440F4", "#4688D3", "#51AB9F"]

function LabFill(props) {
  function updateFill(newFill) {
    props.onChange(newFill)
  }

  return (
    <div className="Fill">
      <div className="Fill-row">
        {props.data.colors1.map((color, index) => {
          return <LabFillColor
                    data={props.data}
                    activity={props.activity}
                    selection={props.selection}
                    color={color}
                    fontColor={props.fontColor[props.selection]}
                    fill={props.fill}
                    tool={props.tool}
                    updateFill={updateFill}
                    updateFontColor={props.updateFontColor}
                    hex={hexes1[index]}
                  />
            })}
      </div>
      <div className="Fill-row">
        {props.data.colors2.map((color, index) => {
          return <LabFillColor
                    data={props.data}
                    activity={props.activity}
                    selection={props.selection}
                    color={color}
                    fontColor={props.fontColor[props.selection]}
                    fill={props.fill}
                    tool={props.tool}
                    updateFill={updateFill}
                    updateFontColor={props.updateFontColor}
                    hex={hexes2[index]}
                  />
            })}
          </div>
    </div>
  );
}

export default LabFill;
