import React, {useState, useRef} from 'react'
import ActivityTitle from './ActivityTitle'
import ActivitySubtitle from './ActivitySubtitle'
import PairGrid from './PairGrid'
import Feedback from './Feedback'
import '../App.css';


const Pairs = (props) => {
  const content = props.data;
  const [selection, setSelection] = useState(null);
  const [selection2, setSelection2] = useState(null);
  const [correctPairs, setCorrectPairs] = useState(0);
  const [itemStatus, setItemStatus] = useState(Array(content.answers.length).fill("default"));
  const [feedback, setFeedback] = useState(null);
  const [correctSubmission, setCorrectSubmission] = useState(false);

  const statusRef = useRef(selection2);
  statusRef.current = selection2;
  
  function selectItem(item) {
    if (selection === null) {
      setSelection(item)
    }
    else if (item !== selection && selection2 === null) {
      setSelection2(item)
      const currentStatus = itemStatus.slice();

      // If the selection is a correct pair
      if (content.pairs[selection] === item) {
        currentStatus[selection] = "correct";
        currentStatus[item] = "correct";
        setCorrectPairs(correctPairs + 1);
        if (correctPairs === ((itemStatus.length / 2) - 1)) {
          submit();
        }
        setTimeout(clearCorrectPair, 700);
      }

      // If the selection is an incorrect pair
      else {
        currentStatus[selection] = "incorrect";
        currentStatus[item] = "incorrect";
        setTimeout(clearIncorrectPair, 700);
      }
      setItemStatus(currentStatus);
    }
  }
  function clearCorrectPair() {
    clearPair("finished");
  }

  function clearIncorrectPair() {
    clearPair("default");
  }

  function clearPair(text) {
    const newStatus = itemStatus.slice();
    newStatus[selection] = text;
    newStatus[statusRef.current] = text;
    setItemStatus(newStatus);
    setSelection(null);
    setSelection2(null);
  }

  function giveFeedback(newFeedback) {
    setFeedback(newFeedback);
  }

  function dismissFeedback() {
    setFeedback(null);
  }

  function submit() {
    giveFeedback(content.feedback[0]);
    setCorrectSubmission(true);
  }

  function updateActivity() {
    setFeedback(null);
    setCorrectSubmission(false);
    props.nextStep();
  }

  return (
    <div className="Question">      
      <div className="Activity-content">
        <div className="Activity-info">
          <ActivityTitle data={props.data} />
          <ActivitySubtitle data={props.data} />
          <p>Tap the pairs</p>
          <PairGrid 
            data={props.data} 
            selectItem={selectItem}
            selection={selection}
            status={itemStatus}
          />
        </div>
      </div>
      {feedback ? 
        <Feedback 
          feedback={feedback}
          dismissFeedback={dismissFeedback} 
          correctSubmission={correctSubmission}
          updateActivity={updateActivity}
          data={content}
        />
      : null}
    </div>
  );
};

export default Pairs;