import React from 'react';
import PathItem from './PathItem'
import Navbar from './Navbar'
import section1 from '../data/section1.json'
import section2 from '../data/section2.json'
import section3 from '../data/section3.json'
import {
  BrowserRouter as Router,
Link
} from "react-router-dom";

const sections = [section1, section2, section3];

const Path = (props) => {
  const sectionClasses = ["Path-section1", "Path-section2", "Path-section3"]
  return (
    <div>
      <Navbar />
      {sections.map((section, sectionIndex) => {
        return(
          <>
            <div className="Path-divider">
              <div className="Path-divider-line"></div>
              <div className="Path-divider-outline">
                <div className="Path-divider-content">
                  {"SECTION " + (sectionIndex + 1)}
                </div>
              </div>
              <div className="Path-divider-line Path-divider-line-two"></div>
            </div>
            <div className={sectionClasses[sectionIndex]}>
              {Object.keys(section).map((activity, index) => {
                return  <Link to={"/learn/" + index}>
                          <PathItem 
                            name={activity}
                            number={index}
                            openActivity={props.openActivity}
                            section={sectionIndex}
                            status={props.progress[sectionIndex][index]}
                          />
                        </Link>
              })}
            </div>
            {sectionIndex === 0 
              ? <div className="coming-soon-outline">
                  <div className="coming-soon" style={{textAlign: "center", fontWeight: 600, fontSize: "20px"}}>
                    <p className="coming-soon-text1">SECTIONS 2 &amp; 3</p>
                    <p className="coming-soon-text2">Coming soon!</p>
                  </div>
                </div> 
              : null
            }
          </>
        )
      })}
        <Router></Router>
    </div>
  );
};

export default Path;