import React from 'react';
import '../App.css';
import cursor from '../icons/cursor.svg'

function LabExample(props) {
  return (
    <div 
      className={props.data.image} 
    >
      {props.data.inputIcon ? <img src={cursor} alt="cursor" /> : null}
      {props.data.inputIcon ? null : props.data.exampleContent}
    </div>
  );
}

export default LabExample;
