import React from 'react'
import '../App.css';

const CarouselDot = (props) => {
  function moveToImage() {
    props.moveToImage(props.index);
  }
  return (
    <a 
      className={props.index === props.imgCount ? "Carousel-dot Carousel-current-dot" : "Carousel-dot"} 
      onClick={moveToImage}
      href={props.href}>
    </a>
  );
};

export default CarouselDot;