import React, {useState} from 'react'
import '../App.css';
import '../animations.css'
import checkIcon from '../images/checkIcon.svg'
import sparkle from '../icons/sparkleIcon.svg'
import search from '../icons/searchIcon.svg'
import lightning from '../icons/lightingIcon.svg'
import check from '../icons/checkIcon.svg'
import checkCircle from '../icons/checkCircleIcon.svg'
import camera from '../icons/cameraIcon.svg'

const icons = [sparkle, search, lightning, check, checkCircle, camera];

const DemoButton = (props) => {
  const [interactionState, setInteractionState] = useState("Default");
  const objectStyle = props.customized ? ["Object Example purple no-border purple-shadow r-8", "Object Example purple-dark no-border r-8", "Object Example purple no-border r-8", "Object Example purple no-border r-8"] : ["Object Example salmon no-border salmon-shadow r-8", "Object Example salmon-dark no-border r-8", "Object Example salmon no-border r-8", "Object Example salmon no-border r-8"];
  
  const defaulted = (interactionState === "Default");
  const success = (interactionState === "Success");
  const pressed = (interactionState === "Pressed");
  const loading = (interactionState === "Loading");

  function pressedState() {
    if (defaulted) {
      setInteractionState("Pressed")
      props.dismissInstructions();
      props.dismissFeedback();
    }
  }

  function loadingState() {
    if (pressed || defaulted) {
      setInteractionState("Loading");
      setTimeout(successState, 3000);
    }
  }

  function successState() {
    setInteractionState("Success");
    setTimeout(defaultState, 2000);
  }

  function defaultState() {
    setInteractionState("Default");
    props.submit();
  }

  return (
    <div className="DemoButton">
      {props.customized ? null : <p className="DemoButton-state unselectable">{interactionState + " state"}</p>}
      <div className={interactionState === "Pressed" ? objectStyle[1] : interactionState === "Loading" ? objectStyle[2] : interactionState === "Success" ? objectStyle[3] : objectStyle[0]} onTouchStart={pressedState} onMouseDown={pressedState} onTouchEnd={loadingState} onMouseUp={loadingState}>
        {loading
          ? <div class={(props.customized && props.animation !== null) ? "container animation-" + props.animation : "container animation-2"}>
              <div class="shape shape1"></div>
              <div class="shape shape2"></div>
              <div class="shape shape3"></div>
              <div class="shape shape4"></div>
            </div>
          : null
        }
        <div className="success-message">
          <img className={success ? "success success-icon-visible unselectable" : "success success-icon-invisible unselectable"} src={(props.customized && props.icon !== null) ? icons[props.icon] : checkIcon} alt="checkmark icon" />
          <p className={success || defaulted ? "success message-visible unselectable" : pressed ? "message-visible unselectable" : "success message-invisible unselectable"}>{success ? "Subscribed!" : "Subscribe"}</p>
        </div>
      </div>
    </div>
  );
}

export default DemoButton;
