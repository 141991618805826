import React, {useState} from 'react'
import hierarchy22 from '../images/hierarchy22.jpg'
import hierarchy23 from '../images/hierarchy23.jpg'
import squint from '../images/squint.svg'
import unsquint from '../images/unsquint.svg'
import '../App.css';

const ToggleImage = (props) => {
  const images = [hierarchy22, hierarchy23];
  const [toggle, setToggle] = useState(0);
  function toggleImage() {
    if (toggle === 0) {
      setToggle(1)
    }
    else {
      setToggle(0)
    }
  }
  return (
    <>
      <img className="ToggleImage" src={images[toggle]} alt="mockup" />
      <div className="ToggleImage-button" onClick={toggleImage} >
        <img className="ToggleImage-button-icon" src={toggle ? unsquint : squint} alt="squint icon" />
        {toggle ? "Squint off" : "Squint"}
      </div>
    </>
  );
}; 

export default ToggleImage;