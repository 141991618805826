import React from 'react';
import Tool from './Tool.js';
import '../App.css';
import fill from '../images/fill.svg'
import stroke from '../images/stroke.svg'
import radius from '../images/radius.svg'
import shadow from '../images/shadow.svg'
import submit from '../images/submit.svg'
import font from '../images/font.svg'
import fontWeight from '../images/fontWeight.svg'
import fontSize from '../images/fontSize.svg'
import scale from '../images/scale.svg'
import margin from '../images/margin.svg'
import padding from '../images/padding.svg'
const oneIcons = [fill, stroke, radius, shadow, submit]
const twoIcons = [scale, margin, submit]
const threeIcons = [font, fill, submit]
const fourIconsA = [fontSize, fontWeight, margin, fill, submit]
const fourIconsB = [fontSize, fontWeight, margin, fill, submit]
const fourIconsC = [fontSize, fontWeight, margin, fill, submit]
const fiveIcons = [padding, fill, radius, submit]
const sixIconsA = [scale, margin, submit]
const sixIconsB = [fontSize, fontWeight, margin, submit]
const sixIconsC = [fontSize, fontWeight, margin, submit]
const sixIconsD = [scale, margin, submit]
const sixIconsE = [padding, fill, radius, shadow, submit]
const one = ["Fill", "Stroke", "Radius", "Shadow", "Submit"]
const two = ["Scale", "Margin", "Submit"]
const three = ["Font", "Color", "Submit"]
const fourA = ["Size", "Weight", "Margin", "Color", "Submit"]
const fourB = ["Size", "Weight", "Margin", "Color", "Submit"]
const fourC = ["Size", "Weight", "Margin", "Color", "Submit"]
const five = ["Padding", "Fill", "Radius", "Submit"]
const sixA = ["Scale", "Margin", "Submit"]
const sixB = ["Size", "Weight", "Margin", "Submit"]
const sixC = ["Size", "Weight", "Margin", "Submit"]
const sixD = ["Scale", "Margin", "Submit"]
const sixE = ["Padding", "Fill", "Radius", "Shadow", "Submit"]
const lessons = [[one], [two], [three], [fourA, fourB, fourC], [five], [sixA, sixB, sixC, sixD, sixE]]
const icons = [[oneIcons], [twoIcons], [threeIcons], [fourIconsA, fourIconsB, fourIconsC], [fiveIcons], [sixIconsA, sixIconsB, sixIconsC, sixIconsD, sixIconsE]]


function Toolbar(props) {
  const selection = (props.selection === null) ? 0 : props.selection;
  function showTool(currentTool) {
    props.onChange(currentTool)
  }
  return (
    <div className={props.step > 0 ? "Toolbar Toolbar-appear" : "Toolbar"}>
      <div className="Toolbar-container">
        {lessons[props.activity][selection].map((tool, index) => {
          return <Tool
                    name={tool}
                    icon={icons[props.activity][selection][index]}
                    showTool={showTool}
                    index={index}
                    submit={props.submit}
                  />
          })}
      </div>
    </div>
  );
}

export default Toolbar;
