import React from 'react';
import '../App.css';

function Tool(props) {

  const name = (props.name === "MoveY" || props.name === "MoveX") ? "Move" : props.name;

  function showTool() {
    props.showTool(props.name)
    if (props.name === "Submit") {
      props.submit();
    }
  }
  
  return (
    <div className="Tool" onClick={showTool}>
        <img src={props.icon} alt={props.name} />
        <p className="Tool-label">{name}</p>
    </div>
  );
}

export default Tool;
