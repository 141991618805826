
// add useContext
import React, {useState} from 'react';
import LabPath from './LabPath';
import LabActivity from './LabActivity';
import section1 from '../data/lab1.json'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const sections = [section1];

const Lab = () => {
  const [section, setSection] = useState(0);
  const [activity, setActivity] = useState(parseInt(window.location.pathname.slice(-1)));
  const [progress, setProgress] = useState([["unlocked", "unlocked", "unlocked", "unlocked"]]);
  const [storedAnimation, setStoredAnimation] = useState(null);
  const [storedIcon, setStoredIcon] = useState(null);
  const [storedMessages, setStoredMessages] = useState([null]);
  function openActivity(sectionNumber, activityNumber) {
    setSection(sectionNumber);
    setActivity(activityNumber);
  }
  function closeActivity() {
    setActivity(null)
  }
  function updateProgress(section, activity) {
    const currentProgress = [...progress];
    currentProgress[section][activity] = "complete";
    currentProgress[section][activity + 1] = "unlocked";
    setProgress(currentProgress);
    setActivity(null);
  }
  function updateStoredAnimate(newValue) {
    setStoredAnimation(newValue);
  }

  function updateStoredIcon(newValue) {
    setStoredIcon(newValue);
  }

  function storeMessage(number, message) {
    const currentMessages = storedMessages.slice();
    currentMessages[number - 1] = message;
    setStoredMessages(currentMessages);
  }
  return (
    <Router>
      <Switch>
        <Route path="/lab/practice">
          <LabActivity 
            section={section}
            activity={activity}
            data={Object.values(sections[section])[activity]}
            closeActivity={closeActivity}
            updateProgress={updateProgress} 
            storeMessage={storeMessage}
            updateStoredAnimate={updateStoredAnimate}
            updateStoredIcon={updateStoredIcon}
            storedMessages={storedMessages}
            storedIcon={storedIcon}
            storedAnimation={storedAnimation}
          />
        </Route>
        <Route path="/lab">
          <LabPath 
              openActivity={openActivity}
              progress={progress} 
              activity={activity}
          />
        </Route>
      </Switch>
    </Router>
  );
};

export default Lab;