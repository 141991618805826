import React from 'react'
import '../App.css';

const MultiChoiceStackAnswer = (props) => {
  const content = props.data;
  function submitAnswer() {
    props.submitAnswer(props.number)
  }
  return (
    <div 
      className={props.number === props.selection ? "MultiChoice-answer MultiChoiceStack-answer-selected" : "MultiChoice-answer MultiChoiceStack-answer"} onClick={submitAnswer}>
      {content.answers 
        ? <p className="MultiChoice-answer-text">
            {content.splitText 
              ? <span>
                  {content.answers[props.number].substring(0, content.splitText[props.number])}
                  <br />
                  {content.answers[props.number].substring(content.splitText[props.number])}
                </span> 
              : content.answers[props.number]
            }
          </p>
        : null
      }
    </div>  
  );
};

export default MultiChoiceStackAnswer;