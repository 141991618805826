import React, {useState} from 'react'
import ClickImageArea from './ClickImageArea'
import ActivityTitle from './ActivityTitle'
import ActivitySubtitle from './ActivitySubtitle'
import '../App.css';

const ClickImage = (props) => {
  const [selection, setSelection] = useState(3);
  const content = props.data;
  function selectImage(number) {
    setSelection(number);
  }
  function submitAnswer() {
    props.submitAnswer(selection);
  }
  return (
    <>
      <div className="Activity-content">
        <div className="Activity-info">
          <ActivityTitle data={props.data} />
          <ActivitySubtitle data={props.data} />
          <div className="ClickImages">
            {content.images.map((image, index) => {
              return( 
                <ClickImageArea
                  selectImage={selectImage}
                  index={index}
                  selection={selection}
                  data={props.data}
                  activity={props.activity}
                  step={props.step}
                />
              )
            })}
          </div>
        </div>
      </div>
      {selection === 3 || props.feedback
        ? null
        : <div className="Activity-btn-container">
            <div className="Activity-primary-btn" onClick={submitAnswer}>{content.buttonText}</div>
          </div>
      }
    </>
  );
}; 

export default ClickImage;