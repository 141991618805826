import React from 'react'
import '../App.css';

const ActivitySubtitle = (props) => {
  const content = props.data;
  return (
    <div className="Activity-subtitle">
      {content.substring 
        ? <>
            {content.text.substring(0, content.substring[0])}
            <span className="semibold">{content.text.substring(content.substring[0], content.substring[1])}</span>
            {content.text.substring(content.substring[1], content.substring[2])}
            <span className="semibold">{content.text.substring(content.substring[2], content.substring[3])}</span>
            {content.text.substring(content.substring[3])}
          </>
        : content.text
      }
    </div>    
  );
};

export default ActivitySubtitle;