import React from 'react';
import '../App.css';

function LabAnimateOptions(props) {
  function updateAnimate() {
    props.updateAnimate(props.data.animations[props.index])
  }

  return (
    <div className={(props.animate === props.data.animations[props.index]) ? "selected-dark" : "LabAnimateOption"} onClick={updateAnimate}>
      <div>
        <div class={"container animation-" + props.data.animations[props.index]}>
          <div class="shape shape1"></div>
          <div class="shape shape2"></div>
          <div class="shape shape3"></div>
          <div class="shape shape4"></div>
        </div>
      </div>
    </div>
  );
}

export default LabAnimateOptions;