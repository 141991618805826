import React from 'react';
import '../App.css';


const ArtboardText = (props) => {
  function selectItem() {
    props.selectItem(props.element);
  }
  const fontSize = props.fontSize[props.element] + "px"
  const margin = props.margin[props.element] + "px"
  return (
    <>
      <p 
        className={props.selection === props.element ? "Artboard-content-selected Artboard-text" : "Artboard-content-unselected Artboard-text"} 
        onClick={selectItem}
        style={{
          fontSize: fontSize, 
          color: props.fontColor[props.element], 
          fontWeight: props.fontWeight[props.element], 
          marginTop: margin, 
          marginBottom: margin}}
      >
        {props.activity === 5 && props.element === 1 ? <><span className="purple-text">Home cleaning</span> subscriptions</> : <>Pick a plan. We do the rest.</>}
      </p>
    </>
  );
}

export default ArtboardText;
