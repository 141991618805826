import React from 'react';
import Tool from '../components/Tool.js';
import '../App.css';
import fill from '../images/fill.svg'
import stroke from '../images/stroke.svg'
import radius from '../images/radius.svg'
import shadow from '../images/shadow.svg'
import submit from '../images/submit.svg'
import font from '../images/font.svg'
import fontWeight from '../images/fontWeight.svg'
import fontSize from '../images/fontSize.svg'
import scale from '../images/scale.svg'
import margin from '../images/margin.svg'
import padding from '../images/padding.svg'
import animate from '../images/animate.svg'
import icon from '../icons/heartIcon.svg'
import align from '../icons/alignIcon.svg'
import moveY from '../icons/moveY.svg'


function LabToolbar(props) {
  const tools = props.data.tools;
  const selection = (props.selection === null) ? 0 : props.selection;
  const icons = tools[selection].map(showIcon);
  function showIcon(tool) {
    switch(tool) {
      case "Fill":
        return fill;
      case "Stroke":
          return stroke;
      case "Radius":
        return radius;
      case "Shadow":
          return shadow;
      case "Font":
        return font;
      case "FontWeight":
          return fontWeight;
      case "Size":
        return fontSize;
      case "Scale":
          return scale;
      case "Margin":
        return margin;
      case "Padding":
          return padding;
      case "Animate":
        return animate;
      case "Icon":
        return icon;
      case "Align":
        return align;
      case "Color":
        return fill;
      case "MoveY":
        return moveY;
      default:
        return submit
    }
  }
  function showTool(currentTool) {
    props.onChange(currentTool)
  }
  return (
    <div className={props.step > 0 ? "Toolbar Toolbar-appear" : "Toolbar"}>
      <div className="Toolbar-container">
        {tools[selection].map((tool, index) => {
          return <Tool
                    name={tool}
                    icon={icons[index]}
                    showTool={showTool}
                    index={index}
                    submit={props.submit}
                  />
          })}
      </div>
    </div>
  );
}

export default LabToolbar;
