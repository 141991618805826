import React from 'react'
import MultiChoiceStackAnswer from './MultiChoiceStackAnswer'
import MultiChoiceStackImage from './MultiChoiceStackImage'
import MultiChoiceStackTextImage from './MultiChoiceStackTextImage'
import '../App.css';

const MultiChoiceStack = (props) => {
  const content = props.data;
  return (
    <div className="MultiChoiceStack">   
      {content.centerText ? <p className="center-text">{content.centerText}</p> : null}
      {content.answers.map((answer, index) => {
        return (
        content.questionType === "multiTextImageChoice" 
          ? <MultiChoiceStackTextImage 
              answer={content.answers[index]}
              activity={props.activity}
              number={index} 
              step={props.step}
              submitAnswer={props.submitAnswer}
              data={props.data}
              selection={props.selection}
            />
          : content.questionType === "multiImageChoice" 
          ? <MultiChoiceStackImage 
              answer={content.answers[index]}
              activity={props.activity}
              number={index} 
              step={props.step}
              submitAnswer={props.submitAnswer}
              data={props.data}
              selection={props.selection}
            />
          : <MultiChoiceStackAnswer 
              answer={content.answers[index]}
              number={index} 
              submitAnswer={props.submitAnswer}
              data={props.data}
              selection={props.selection}
            />
            )
      })}  
    </div>
  );
};

export default MultiChoiceStack;