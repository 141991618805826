import React from 'react'
import hierarchy2 from '../images/hierarchy2.png'
import hierarchy3 from '../images/hierarchy3.png'
import hierarchy4 from '../images/hierarchy4.png'
import hierarchy3a from '../images/hierarchy3a.png'
import hierarchy4a from '../images/hierarchy4a.png'
import hierarchy2a from '../images/hierarchy2a.png'
import hierarchy3b from '../images/hierarchy3b.png'
import hierarchy4b from '../images/hierarchy4b.png'
import hierarchy3c from '../images/hierarchy3c.png'
import typoHier1a from '../images/typoHier1a.svg'
import typoHier1b from '../images/typoHier1b.svg'
import typoHier2a from '../images/typoHier2a.svg'
import typoHier2b from '../images/typoHier2b.svg'
import typoHier3a from '../images/typoHier3a.svg'
import typoHier3b from '../images/typoHier3b.svg'
import typoHierText1a from '../images/typoHierText1a.svg'
import typoHierText1b from '../images/typoHierText1b.svg'
import typoHierText2a from '../images/typoHierText2a.svg'
import typoHierText2b from '../images/typoHierText2b.svg'
import typoHierText3a from '../images/typoHierText3a.svg'
import typoHierText3b from '../images/typoHierText3b.svg'
import '../App.css';

const ClickImageArea = (props) => {
  const content = props.data;
  const images = [hierarchy2, hierarchy3, hierarchy4];
  const images1 = [hierarchy2, hierarchy3a, hierarchy4a];
  const images2 = [hierarchy2a, hierarchy3b, hierarchy4b];
  const images3 = [hierarchy2a, hierarchy3c, hierarchy4];
  const imageSet = [images1, images2, images3, images];

  const hierImages = [typoHier1a, typoHier2a, typoHier3a];
  const hierImages1 = [typoHier1b, typoHier2a, typoHier3a];
  const hierImages2 = [typoHier1a, typoHier2b, typoHier3a];
  const hierImages3 = [typoHier1a, typoHier2a, typoHier3b];
  const hierImageSet = [hierImages1, hierImages2, hierImages3, hierImages];

  const hierTextImages = [typoHierText1a, typoHierText2a, typoHierText3a];
  const hierTextImages1 = [typoHierText1b, typoHierText2a, typoHierText3a];
  const hierTextImages2 = [typoHierText1a, typoHierText2b, typoHierText3a];
  const hierTextImages3 = [typoHierText1a, typoHierText2a, typoHierText3b];
  const hierTextImageSet = [hierTextImages1, hierTextImages2, hierTextImages3, hierTextImages];

  const currentImages = (props.activity === 3 && props.step === 3) ? hierImageSet : (props.activity === 3 && props.step === 10) ? hierTextImageSet : imageSet;
  function selectImage() {
    props.selectImage(props.index);
  }

  return (
        <img 
          className="ClickImage"
          src={currentImages[props.selection][props.index]} 
          alt={content.imageCaptions[props.index]} 
          onClick={selectImage}
        />
  );
}; 

export default ClickImageArea;