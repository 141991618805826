import React from 'react';
import '../App.css';


const ArtboardItem = (props) => {
  function selectItem() {
    props.selectItem(props.index);
  }
  const fontSize = props.fontSize[props.index] + "px"
  const margin = props.margin[props.index] + "px"
  return (
    <>
      <p 
        className={props.selection === props.index ? "Artboard-content-selected Artboard-text" : "Artboard-content-unselected Artboard-text"} 
        onClick={selectItem}
        style={{
          fontSize: fontSize, 
          color: props.fontColor[props.index], 
          fontWeight: props.fontWeight[props.index], 
          marginTop: margin, 
          marginBottom: margin}}
      >
        {props.data.items[props.index]}
      </p>
    </>
  );
}

export default ArtboardItem;
