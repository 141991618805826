import React from 'react';
import '../App.css';

function Example(props) {
  return (
    <div 
      className="Object Example purple no-border purple-shadow" 
      style={{borderRadius: "30px"}}
    >
      {props.activity === "activity2" ? "Primary Button" : "Get Started"}
    </div>
  );
}

export default Example;
