import React from 'react';
import oneTwoImageOne from '../images/oneTwoImage1.svg'
import oneTwoImageTwo from '../images/oneTwoImage2.svg'
import ArtboardItem from './ArtboardItem'
import ArtboardImage from './ArtboardImage'
import ArtboardButton from './ArtboardButton'
import ArtboardText from './ArtboardText'
import '../App.css';


const Artboard = (props) => {
  const radius = props.radius + "px";
  const vPadding = props.vPadding + "px";
  const hPadding = props.hPadding + "px";
  const toggleBorder = props.fill === "no-color" ? "1px solid white": "1px solid rgba(0,0,0,0)";
  const items = [['button'], ['image'], ['text'], ['text', 'text', 'text'], ['button'], ['text', 'text', 'button']]
  function selectItem(item) {
    props.selectItem(item);
    if (props.step === 0) {
      props.updateStep(1);
      props.displayPrompt();
    }
  }
  function updateStep() {
    if (props.step === 0) {
      props.updateStep(1);
      props.displayPrompt();
    }
    if (props.activity === 1) {
      props.selectItem(0);
    }
  }
  return (
    <div className="Object-container">
      {props.activity === 0 
        ? <div 
            className={"Object Object-edit " + props.fill + " " + props.stroke + " " + props.shadow} 
            style={{borderRadius: radius}}
            onClick={updateStep}
          >
            Get Started
          </div>
        : props.activity === 1 
        ?  <div className="Artboard-background">
              <div className="Artboard-mobile Artboard-oneTwo">
                <div className="Artboard-project-content">
                  <img className="oneTwo-image-one" src={oneTwoImageOne} alt="heading and logo" />
                  <ArtboardImage 
                    margin={props.margin}
                    scale={props.scale}
                    element={0}
                    updateStep={updateStep}
                    selection={props.selection}
                    selectItem={selectItem}
                    activity={props.activity}
                    data={props.data}
                  />
                  <img className="oneTwo-image-two" src={oneTwoImageTwo} alt="CTA button" />
                </div>
              </div>
              </div>
        : props.activity === 4 
        ? <div className="Artboard Artboard-one-four">
            <div className="Artboard-content Artboard-content-one-four" >
              <h2 className="heading-one-four">All-you-can-eat cake</h2>
              <p className="text-one-four">Come stuff yourself</p>
              <div 
                className={"Artboard-button button-one-four " + props.fill}
                onClick={updateStep}
                style={{
                  border: toggleBorder,
                  paddingTop: vPadding,
                  paddingBottom: vPadding,
                  paddingLeft: hPadding,
                  paddingRight: hPadding,
                  borderRadius: radius}}>
                    Gimme now
              </div> 
            </div>
          </div>
        : props.activity === 5
        ? <div className="Artboard-background">
            <div className="Artboard-mobile">
              <div className="Artboard-project-content">
                <ArtboardImage 
                  margin={props.margin}
                  scale={props.scale}
                  element={0}
                  updateStep={updateStep}
                  selection={props.selection}
                  selectItem={selectItem}
                  activity={props.activity}
                />
                <ArtboardText 
                  element={1}
                  data={props.data}
                  activity={props.activity}
                  selection={props.selection}
                  selectItem={selectItem}
                  fontSize={props.fontSize}
                  fontWeight={props.fontWeight}
                  fontColor={props.fontColor}
                  margin={props.margin}
                />
                <ArtboardText 
                  element={2}
                  data={props.data}
                  selection={props.selection}
                  selectItem={selectItem}
                  fontSize={props.fontSize}
                  fontWeight={props.fontWeight}
                  fontColor={props.fontColor}
                  margin={props.margin}
                />
                <ArtboardImage 
                  margin={props.margin}
                  scale={props.scale}
                  element={3}
                  updateStep={updateStep}
                  selection={props.selection}
                  selectItem={selectItem}
                  activity={props.activity}
                />
                <ArtboardButton 
                  fill={props.fill}
                  stroke={props.stroke}
                  shadow={props.shadow}
                  radius={props.radius}
                  margin={props.margin}
                  vPadding={props.vPadding}
                  hPadding={props.hPadding}
                  element={4}
                  updateStep={updateStep}
                  selection={props.selection}
                  selectItem={selectItem}
                />
              </div>
            </div>
          </div>
        : <div className="Artboard">
            <div className="Artboard-content">
              {items[props.activity].map((item, index) => {
                return (
                  <ArtboardItem 
                    item={item}
                    index={index}
                    data={props.data}
                    selection={props.selection}
                    selectItem={selectItem}
                    fontSize={props.fontSize}
                    fontWeight={props.fontWeight}
                    fontColor={props.fontColor}
                    fill={props.fill}
                    margin={props.margin}
                    padding={props.padding}
                  />
                )
              })}  
            </div>
          </div>
      }
    </div>
  );
}

export default Artboard;
