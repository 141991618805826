import React from 'react';
import slash from '../images/slash.svg';
import '../App.css';

function LabStrokeColor(props) {
  function updateStroke() {
    props.updateStroke(props.strokeColor)
  }
  let strokeIcon = (props.strokeColor === "no-border") ? "no-border-icon" : (props.strokeColor === "white-border" && props.stroke !== "white-border") ? "white-border-icon" : props.strokeColor;
  return (
    <div className={props.stroke === props.strokeColor ? "selected" : null}>
      <div className={"StrokeColor " + strokeIcon} onClick={updateStroke}>
        {props.strokeColor === "no-border" ? <img src={slash} alt="" /> : null}
      </div>
    </div>
  );
}

export default LabStrokeColor;
