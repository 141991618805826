import React from 'react'
import '../App.css';
import hMargin from '../images/hMargin-dark.svg'
import vMargin from '../images/vMargin-dark.svg'
import hPadding from '../images/hPadding-dark.svg'
import vPadding from '../images/vPadding-dark.svg'

const icons = [hMargin, vMargin, hPadding, vPadding];

const MultiChoiceGridAnswer = (props) => {
  function submitAnswer() {
    props.submitAnswer(props.number)
  }
  return (
    <div 
      className={props.number === props.selection ? "MultiChoice-answer MultiChoiceGrid-answer-selected" : "MultiChoice-answer MultiChoiceGrid-answer"} onClick={submitAnswer}>
      {props.data.icons ? <img className="MultiChoiceGridAnswer-icon" src={icons[props.number]} alt={props.data.iconAlt[props.number]} /> : null}
      {props.answer}
    </div>  
  );
};

export default MultiChoiceGridAnswer;