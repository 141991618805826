import React, {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import congrats from '../images/congrats.svg'
import '../App.css';

const Congrats = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const titles = ["Nicely done!", "Woohoo! Look at you!", "You're on fire!", "Boomshakala", "You're an inspiration to us all", "Ah, the learner has become the master"]
  const subtitles = ["You completed the intro!", "You completed the lesson!", "You aced this lesson!", "Way to go!", "Amazing work! Now we'll build the welcome page next.", "Thanks for trying Merlin! We'll have more lessons ready soon!"]
  return (
    <div className="Statement">
      <div className="Title-content">
        <img className="Activity-image Congrats-image" src={congrats} alt="A wizard congratulating you" />
        <p className="Activity-title Congrats-title">{titles[props.activity]}</p>
        <p className="Activity-subtitle Congrats-subtitle">{subtitles[props.activity]}</p>
        <Link className="Link-button" to={"/"}><div className="Activity-primary-btn Title-btn" onClick={props.closeActivity}>Continue</div></Link>
        <Router></Router>
      </div>
    </div>
  );
};

export default Congrats;