import React from 'react';
import vPadding from '../images/vPadding-dark.svg'
import hPadding from '../images/hPadding-dark.svg'
import '../App.css';

function Slider(props) {
  function updateRadius(event) {
    props.updateRadius(event.target.value);
  }
  function updateScale(event) {
    props.updateScale(props.selection, event.target.value);
  }
  function updateMargin(event) {
    props.updateMargin(props.selection, event.target.value);
  }
  function updateHPadding(event) {
    props.updateHPadding(event.target.value);
  }
  function updateVPadding(event) {
    props.updateVPadding(event.target.value);
  }
  function updateY(event) {
    props.updateY(event.target.value);
  }
  function updateFontSize(event) {
    props.updateFontSize(props.selection, event.target.value);
  }
  function updateFontWeight(event) {
    props.updateFontWeight(props.selection, event.target.value);
  }
  return (
    <div className="Fill">
      <div className="slider-container">
        {props.tool === 'Radius' 
          ? <>
              <div className="slider-value">{props.radius}</div>
              <input 
                type="range" 
                min="0" 
                max="50" 
                value={props.radius} 
                step="1" 
                onChange={updateRadius} 
                className="slider" 
              />
            </>
          : props.tool === 'Scale' 
          ? <>
              <div className="slider-value">{props.scale[props.selection]}</div>
              <input 
                type="range" 
                min="0" 
                max="300" 
                value={props.scale[props.selection]} 
                step="5" 
                onChange={updateScale} 
                className="slider" 
              />
            </>
          : props.tool === 'Margin'
          ? <>
              <div className="slider-value">{props.margin[props.selection]}</div>
              <input 
                type="range" 
                min="0" 
                max="50" 
                value={props.margin[props.selection]} 
                step="1" 
                onChange={updateMargin} 
                className="slider" 
              />
            </>
          : props.tool === 'Padding'
          ? <div className="Slider-double">
              <div className="Slider-double-row Slider-top-row">
                <div className="slider-value">{props.hPadding}</div>
                <img className="slider-icon" src={hPadding} alt="Horizontal padding" />
                <input 
                  type="range" 
                  min="0" 
                  max="100" 
                  value={props.hPadding} 
                  step="1" 
                  onChange={updateHPadding} 
                  className="slider " 
                />
              </div>
              <div className="Slider-double-row">
                <div className="slider-value">{props.vPadding}</div>
                <img className="slider-icon2" src={vPadding} alt="Vertical padding" />
                <input 
                  type="range" 
                  min={0} 
                  max={30} 
                  value={props.vPadding} 
                  step="1" 
                  onChange={updateVPadding} 
                  className="slider" 
                />
              </div>
            </div>
          : props.tool === 'y'
          ? <>
              <div className="slider-value">{Math.abs(props.y)}</div>
              <input 
                type="range" 
                min={-50} 
                max={50}
                value={props.y} 
                step={1} 
                onChange={updateY} 
                className="slider" 
              />
            </>
          : props.tool === 'Size' 
          ? <>
              <div className="slider-value">{props.fontSize[props.selection]}</div>
              <input 
                type="range" 
                min={10} 
                max={40}
                value={props.fontSize[props.selection]} 
                step={1} 
                onChange={updateFontSize} 
                className="slider" 
              />
            </>
          : <>
              <div className="slider-value">{props.fontWeight[props.selection]}</div>
              <input 
                type="range" 
                min="100" 
                max="900" 
                value={props.fontWeight[props.selection]} 
                step="100" 
                onChange={updateFontWeight} 
                className="slider" 
              />
            </>
        }
    </div>
  </div>
  );
}

export default Slider;
