import React, {useContext} from 'react';
import {  
  BrowserRouter as Router,
  Route, 
  Switch 
} from 'react-router-dom'
import Signin from './components/Signin'
import Lab from './lab/Lab'
import './App.css';
import ReactGA from 'react-ga';
import {firebaseAuth} from './provider/AuthProvider'
import { hotjar } from 'react-hotjar';
 


function App() {
  const { token } = useContext(firebaseAuth)
  console.log(token)
  ReactGA.initialize('UA-177565823-2');
  hotjar.initialize(2150712);

  return (
    <Router>
      <Switch>
        <Route path='/lab'>
          <Lab />
        </Route>
        <Route path='/'>
          <Signin /> 
        </Route>
      </Switch>
    </Router>
  );
  /*return (
    <Router>
      <Switch>
        <Route path='/' render={rProps => token === null ? <Signin /> : <Home />} />
        <Route exact path='/signin' component={Signin} />
        <Route exact path='/signup' component={Signup} />
        <Route exact path='/lab' component={Lab} />
      </Switch>
    </Router>
  );*/
}

export default App;