import React from 'react';
import LabArtboardText from './LabArtboardText'
import '../App.css';
import cursor from '../icons/cursor.svg'

const LabArtboardInput = (props) => {
  const content = props.data;
  const radius = props.radius + "px";

  return (
    <div 
      className={props.data.styles + " " + props.fill + " " + props.stroke + " " + props.align} 
      style={{borderRadius: radius}}
      onClick={props.updateStep}
    >
      {(props.data.inputIcon && props.step === 1) ? <img src={cursor} alt="cursor" /> : null}
      {content.content[props.step]}
      <div className={props.y < -8 ? "LabArtboardInput-line LabArtboardInput-line-expand" : "LabArtboardInput-line"}></div>
      {content.selectableText 
        ? <LabArtboardText 
            element={0}
            data={props.data}
            activity={props.activity}
            selection={props.selection}
            fontSize={props.fontSize}
            fontColor={props.fontColor}
            y={props.y}
          /> 
        : null}
    </div>
  );
}

export default LabArtboardInput;
