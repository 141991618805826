import React, {useState, useEffect} from 'react'
import CarouselDot from './CarouselDot'
import left from '../images/carousel-left.svg'
import right from '../images/carousel-right.svg'
import intro3 from '../images/introGraphic1.png'
import intro4 from '../images/introGraphic2.png'
import intro5 from '../images/introGraphic3.png'
import hierarchy18 from '../images/hierarchy18.png'
import hierarchy19 from '../images/hierarchy19.png'
import hierarchy20 from '../images/hierarchy20.png'
import hierarchy21 from '../images/hierarchy21.png'
import '../App.css';

const Carousel = (props) => {
  const [imgCount, setImgCount] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const content = props.data
  const intro = [intro3, intro4, intro5]
  const hierarchy = [hierarchy19, hierarchy20, hierarchy18, hierarchy21]
  const allImages = [intro, hierarchy]
  const images = allImages[props.activity]
  const finalImage = imgCount === content.carouselImages.length - 1;
  function moveToImage(image) {
    setImgCount(image);
  }
  function handleScroll(e) {
    let element = e.target

    if (element.scrollLeft === 0) {
      setImgCount(0);
    }
    else if (element.scrollLeft === element.clientWidth + 50) {
      setImgCount(1);
    }
    else if (element.scrollLeft === (element.clientWidth + 50) * 2) {
      setImgCount(2);
    }
    else if (element.scrollLeft === element.scrollWidth - element.clientWidth - 50) {
      setImgCount(3);
    }
  }
  return (
    <div className="Carousel">
    <div className="Activity-content">
      <div className="Activity-info">
        <div className="Carousel-title">{content.title}</div>
      </div>
      <div class={props.activity === 0 ? "Carousel-container Carousel-container-small" : "Carousel-container"}>
        <div class="slides" onScroll={handleScroll}>
          {images.map((image, index) => {
            return  <div className="Carousel-slide-container">
                      <img
                        className={props.activity === 0 ? "Carousel-slide Carousel-slide-small" : "Carousel-slide"}
                        id={"slide-" + index}
                        index={index}
                        src={images[index]} 
                        alt={content.carouselCaptions[index]} 
                      />
                      <p className="Carousel-caption">{content.carouselCaptions[index]}</p>
                    </div>
          })}
        </div>
        {images.map((image, index) => {
          return  <CarouselDot 
                    className="Carousel-dot"
                    href={"#slide-" + index}
                    index={index}
                    moveToImage={moveToImage}
                    imgCount={imgCount}
                  />
        })}
        <a className={imgCount > 0 ? "Carousel-arrow-left Carousel-arrow-appear" : "Carousel-arrow-left"} href={"#slide-" + (imgCount - 1)}><img className={imgCount > 0 ? "Carousel-icon Carousel-icon-appear" : "Carousel-icon"} src={left} alt="left" /></a>
        <a className={imgCount === images.length - 1 ? "Carousel-arrow-right" : "Carousel-arrow-right Carousel-arrow-appear"} href={"#slide-" + (imgCount + 1)}><img className={imgCount === images.length - 1 ? "Carousel-icon" : "Carousel-icon Carousel-icon-appear"} src={right} alt="right" /></a>
    </div>
    </div>
    <div className="Activity-btn-container">
      <a className="Activity-primary-btn" href={imgCount === images.length - 1 ? "#" : "#slide-" + (imgCount + 1)} onClick={finalImage ? props.nextStep : null}>{finalImage ? content.buttonText : "Next"}</a>
    </div>
  </div>
  );
};

export default Carousel;