import React, {useState, useEffect} from 'react'
import Progress from './Progress'
import Title from './Title'
import Statement from './Statement'
import Question from './Question'
import Practice from './Practice'
import Carousel from './Carousel'
import Congrats from './Congrats'
import ReactGA from 'react-ga'
import '../App.css';

const Activity = (props) => {
  const [step, setStep] = useState(0);
  useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  function nextStep() {
    if (props.activity === 0) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lesson',
          action: 'Started lesson 1'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lesson',
          action: 'Completed lesson 1'
        });
      }
    }
    else if (props.activity === 1) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lesson',
          action: 'Started lesson 2'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lesson',
          action: 'Completed lesson 2'
        });
      }
    }
    else if (props.activity === 2) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lesson',
          action: 'Started lesson 3'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lesson',
          action: 'Completed lesson 3'
        });
      }
    }
    else if (props.activity === 3) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lesson',
          action: 'Started lesson 4'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lesson',
          action: 'Completed lesson 4'
        });
      }
    }
    else if (props.activity === 4) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lesson',
          action: 'Started lesson 5'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lesson',
          action: 'Completed lesson 5'
        });
      }
    }
    else if (props.activity === 5) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lesson',
          action: 'Started lesson 6'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lesson',
          action: 'Completed lesson 6'
        });
      }
    }
    setStep(step + 1);
  }
  function goBack() {
    setStep(step - 1);
  }
  function updateProgress() {
    props.updateProgress(props.section, props.activity)
  }
  return (
    <div className="Activity">
      {step === 0 
        ? null
        : <Progress 
            activity={props.activity}
            data={props.data} 
            step={step} 
            closeActivity={props.closeActivity}
            updateProgress={updateProgress}
            goBack={goBack} 
          />
      }
      {step === (props.data.length)
        ? <Congrats 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={updateProgress}
            nextStep={nextStep} /> 
        : props.data[step].type === "title" 
        ? <Title 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : props.data[step].type === "statement" 
        ? <Statement 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : props.data[step].type === "question" 
        ? <Question 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : props.data[step].type === "practice" 
        ? <Practice 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : props.data[step].type === "carousel" 
        ? <Carousel 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : null
      }
    </div>
  );
};

export default Activity;