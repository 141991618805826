
// add useContext
import React, {useState} from 'react';
import Path from './Path';
import Activity from './Activity';
import section1 from '../data/section1.json'
import section2 from '../data/section2.json'
import section3 from '../data/section3.json'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const sections = [section1, section2, section3];

const TempHome = () => {
  const [section, setSection] = useState(0);
  const [activity, setActivity] = useState(parseInt(window.location.pathname.slice(-1)));
  const [progress, setProgress] = useState([["unlocked", "unlocked", "unlocked", "unlocked", "unlocked", "unlocked"], ["locked", "locked", "locked", "locked", "locked", "locked"], ["locked", "locked", "locked", "locked", "locked", "locked"]]);
  function openActivity(sectionNumber, activityNumber) {
    setSection(sectionNumber);
    setActivity(activityNumber);
  }
  function closeActivity() {
    setActivity(null)
  }
  function updateProgress(section, activity) {
    const currentProgress = [...progress];
    currentProgress[section][activity] = "complete";
    currentProgress[section][activity + 1] = "unlocked";
    setProgress(currentProgress);
    setActivity(null);
  }
  return (
    <Router>
      <Switch>
        <Route path="/learn">
          <Activity 
            section={section}
            activity={activity}
            data={Object.values(sections[section])[activity]}
            closeActivity={closeActivity}
            updateProgress={updateProgress} 
          />
        </Route>
        <Route path="/">
          <Path 
              openActivity={openActivity}
              progress={progress} 
              activity={activity}
          />
        </Route>
      </Switch>
    </Router>
  );
};

export default TempHome;