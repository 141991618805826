import React from 'react';
import projectOne from '../images/project1.svg'
import oneTwoImage from '../images/oneTwoImage3.png'
import projectOneLogo from '../images/project1Logo.svg'
import '../App.css';


const ArtboardImage = (props) => {
  function selectItem() {
    props.selectItem(props.element);
  }
  const margin = props.margin[props.element] + "px"
  return (
    <img 
      className={props.selection === props.element ? "Artboard-image Artboard-content-selected" : "Artboard-image Artboard-content-unselected"}
      src={props.activity === 5 && props.element === 0 ? projectOneLogo : props.activity === 5 ? projectOne : oneTwoImage}
      alt="hero"
      style={{
        width: props.scale[props.element] + "px",
        marginTop: margin, 
        marginBottom: margin}}
      onClick={selectItem}
      />
  );
}

export default ArtboardImage;
