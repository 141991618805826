import React from 'react'
import PairGridAnswer from './PairGridAnswer'
import '../App.css';

const PairGrid = (props) => {

  const content = props.data;

  return (
    <div className="PairGrid">      
      {content.answers.map((answer, index) => {
        return (
          <PairGridAnswer 
            answer={answer}
            index={index} 
            selectItem={props.selectItem}
            data={props.data}
            selection={props.selection}
            status={props.status[index]}
          />
        )
      })}
    </div>
  );
};

export default PairGrid;