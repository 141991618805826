import React, {useState} from 'react'
import placeholder from '../images/placeholder.svg'
import hierarchy6 from '../images/hierarchy6.svg'
import hierarchy7 from '../images/hierarchy7.svg'
import sofia from '../images/Sofia.svg'
import xingkai from '../images/Xingkai.svg'
import sofiaTwo from '../images/Sofia2.svg'
import latoTwo from '../images/Lato2.jpg'
import zapfino from '../images/Zapfino.svg'
import buenard from '../images/Buenard.jpg'
import beVietnam from '../images/BeVietnam.jpg'
import balsamiq from '../images/BalsamiqSans.jpg'
import myanmar from '../images/Myanmar.svg'
import neueKabel from '../images/NeueKabel.svg'
import timesNewRoman from '../images/TimesNewRoman.svg'
import chantal from '../images/Chantal.svg'
import acme from '../images/Acme.svg'
import baskerville from '../images/Baskerville.svg'
import buttonsExample1 from '../images/buttonsExample1.svg'
import buttonsExample2 from '../images/buttonsExample2.svg'
import buttonsExample3 from '../images/buttonsExample3.svg'
import btnHier1 from '../images/btnHier1.svg'
import btnHier2 from '../images/btnHier2.svg'
import btnHier3 from '../images/btnHier3.svg'
import btnHier4 from '../images/btnHier4.svg'
import btnPadding1 from '../images/btnPadding1.svg'
import btnPadding2 from '../images/btnPadding2.svg'
import btnPadding3 from '../images/btnPadding3.svg'
import btnPadding4 from '../images/btnPadding4.svg'
import btnContrast1 from '../images/btnContrast1.svg'
import btnContrast2 from '../images/btnContrast2.svg'
import btnContrast3 from '../images/btnContrast3.svg'
import btnContrast4 from '../images/btnContrast4.svg'

import '../App.css';

const MultiChoiceStackImage = (props) => {
  const content = props.data;
  const hierarchy = [null, null, null, null, null, null, null, null, null, [hierarchy7, hierarchy6]]
  const typography = [null, null, [sofia, xingkai], null, [zapfino, latoTwo, sofiaTwo], null, [buenard, beVietnam, balsamiq], [myanmar, neueKabel, timesNewRoman], [chantal, acme, baskerville]]
  const typeHierarchy = [null, null, null, null, null, null, null, null, null, null, [placeholder, placeholder, placeholder]]
  const buttons = [null, null, [buttonsExample1, buttonsExample2, buttonsExample3], null, null, null, null, [btnPadding1, btnPadding2, btnPadding3, btnPadding4], null, [btnHier1, btnHier2, btnHier3, btnHier4], null, [btnContrast1, btnContrast2, btnContrast3, btnContrast4]];
  const lessons = [null, hierarchy, typography, typeHierarchy, buttons]
  const [imgLoaded, setImgLoaded] = useState(false)
  function submitAnswer() {
    props.submitAnswer(props.number)
  }
  const currentImg = new Image();
  currentImg.src = latoTwo;
  currentImg.onload = () => {
    setImgLoaded(true);
  }

  return (
    <div className={props.number === props.selection ? "MultiChoice-image-answer MultiChoiceStackImage-answer-selected" : "MultiChoice-image-answer"} onClick={submitAnswer}>
      {imgLoaded ? <img className="MultiChoice-image" src={lessons[props.activity][props.step][props.number]} alt={content.altText[props.number]} /> : <div className="MultiChoice-image-placeholder"></div>}
    </div>  
  );
};

export default MultiChoiceStackImage;