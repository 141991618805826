import React from 'react';
import '../App.css';


const LabArtboardText = (props) => {

  const fontSize = props.fontSize[props.element] + "px"

  return (
    <>
      <p 
        className={props.selection === props.element ? ("Artboard-content-selected Artboard-text " + props.data.styles2): ("Artboard-content-unselected Artboard-text " + props.data.styles2)} 
        style={{
          fontSize: fontSize, 
          color: props.fontColor[props.element],
          transform: 'translate(0, ' + props.y + 'px)'
        }}
      >
        {props.data.inputText}
      </p>
    </>
  );
}

export default LabArtboardText;
