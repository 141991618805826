import React, {useState, useEffect} from 'react'
import Instructions from './Instructions.js';
import Artboard from './Artboard.js'
import Toolbar from './Toolbar.js'
import Fill from './Fill.js'
import Stroke from './Stroke.js'
import Slider from './Slider.js'
import Shadow from './Shadow.js'
import Feedback from './Feedback.js'
import '../App.css';

const Practice = (props) => {
  const content = props.data;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [step, setStep] = useState(0);
  const [tool, setTool] = useState(null);
  const [fill, setFill] = useState('no-color');
  const [stroke, setStroke] = useState('black-border');
  const [radius, setRadius] = useState(0);
  const [scale, setScale] = useState([100, 100, 100, 100, 100]);
  const [margin, setMargin] = useState([16, 16, 16, 16]);
  const [hPadding, setHPadding] = useState(0);
  const [vPadding, setVPadding] = useState(0);
  const [shadow, setShadow] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [correctSubmission, setCorrectSubmission] = useState(false);
  const [fontSize, setFontSize] = useState(["16", "16", "16"])
  const [fontWeight, setFontWeight] = useState([400, 400, 400])
  const [selection, setSelection] = useState(null);
  const [fontColor, setFontColor] = useState(["#4E5C6A", "#4E5C6A", "#4E5C6A"])

  function updateStep(newStep) {
    setStep(newStep);
  }
  function showTool(currentTool) {
    if (showPrompt === true) {
      setShowPrompt(false);
    }
    setTool(currentTool);
  }
  function updateFill(newFill) {
    setFill(newFill);
  }
  function updateStroke(newStroke) {
    setStroke(newStroke);
  }
  function updateRadius(newRadius) {
    setRadius(newRadius);
  }
  function updateScale(item, newScale) {
    const scales = scale.slice();
    scales[item] = newScale
    setScale(scales);
  }
  function updateMargin(item, newMargin) {
    const margins = margin.slice();
    margins[item] = newMargin;
    setMargin(margins);
  }
  function updateHPadding(newHPadding) {
    setHPadding(newHPadding);
  }
  function updateVPadding(newVPadding) {
    setVPadding(newVPadding);
  }
  function updateShadow(toggleShadow) {
    setShadow(toggleShadow);
  }
  function updateFontSize(item, size) {
    const sizes = fontSize.slice();
    sizes[item] = size;
    setFontSize(sizes);
  }
  function updateFontWeight(item, weight) {
    const weights = fontWeight.slice();
    weights[item] = weight;
    setFontWeight(weights);
  }
  function updateFontColor(item, color) {
    const colors = fontColor.slice();
    colors[item] = color;
    setFontColor(colors);
  }
  function giveFeedback(newFeedback) {
    setFeedback(newFeedback);
  }
  function dismissFeedback() {
    setFeedback(null);
  }
  function displayPrompt() {
    setShowPrompt(true);
  }
  function selectItem(item) {
    setSelection(item)
    setTool(null)
  }
  function submit() {
    
    /* Feedback criteria for Lesson 1 practice */ 

    if (props.activity === 0) {
      if (fill !== 'purple') {
        giveFeedback(content.feedback[0]);
      }
      else if (stroke !== 'no-border' && stroke !== 'purple-border') {
        giveFeedback(content.feedback[1]);
      }
      else if (radius < 28) {
        giveFeedback(content.feedback[2]);
      }
      else if (shadow !== 'purple-shadow') {
        giveFeedback(content.feedback[3]);
      }
      else {
        giveFeedback(content.feedback[4]);
        setCorrectSubmission(true);
      }
    }

    /* Feedback criteria for Lesson 2 practice */ 

    else if (props.activity === 1) {
      if (scale < 230) {
        giveFeedback(content.feedback[0]);
      }
      else if (margin[0] < 19) {
        giveFeedback(content.feedback[1]);
      }
      else if (margin[0] > 35) {
        giveFeedback(content.feedback[2]);
      }
      else {
        giveFeedback(content.feedback[3]);
        setCorrectSubmission(true);
      }
    }

     /* Feedback criteria for Lesson 4 practice */ 

    else if (props.activity === 3) {
      if (fontSize[0] < 20) {
        giveFeedback(content.feedback[0]);
      }
      else if (fontSize[1] < 17) {
        giveFeedback(content.feedback[1]);
      }
      else if (fontSize[1] >= fontSize[0]) {
        giveFeedback(content.feedback[2]);
      }
      else if (fontSize[2] >= fontSize[1] || fontSize[2] >= fontSize[0]) {
        giveFeedback(content.feedback[3]);
      }
      else if (fontWeight[1] > fontWeight[0]) {
        giveFeedback(content.feedback[4]);
      }
      else if (fontWeight[2] > fontWeight[1] || fontWeight[2] > fontWeight[0]) {
        giveFeedback(content.feedback[5]);
      }
      else if (fontWeight[2] > 500) {
        giveFeedback(content.feedback[6]);
      }
      else if (fontColor[0] === "rgba(0,0,0,0)" || fontColor[0] === "#fff" || fontColor[0] === "#c5cfd8" || fontColor[0] === "#F0C44C") {
        giveFeedback(content.feedback[7]);
      }
      else if (fontColor[1] === "rgba(0,0,0,0)" || fontColor[1] === "#fff" || fontColor[1] === "#c5cfd8" || fontColor[1] === "#F0C44C") {
        giveFeedback(content.feedback[7]);
      }
      else if (fontColor[2] === "rgba(0,0,0,0)" || fontColor[2] === "#fff" || fontColor[2] === "#c5cfd8" || fontColor[2] === "#F0C44C") {
        giveFeedback(content.feedback[7]);
      }
      else {
        giveFeedback(content.feedback[8]);
        setCorrectSubmission(true);
      }
    }

    /* Feedback criteria for Lesson 5 practice */ 

    else if (props.activity === 4) {
      if (hPadding < 12) {
        giveFeedback(content.feedback[0]);
      }
      else if (hPadding > 52) {
        giveFeedback(content.feedback[1]);
      }
      else if (vPadding < 5) {
        giveFeedback(content.feedback[2]);
      }
      else if (vPadding > 15) {
        giveFeedback(content.feedback[3]);
      }
      else if (fill === "no-color" || fill === "gray2" || fill === "gray3" || fill === "green2" || fill === "red" || fill === "pink") {
        giveFeedback(content.feedback[4]);
      }
      else {
        giveFeedback(content.feedback[5]);
        setCorrectSubmission(true);
      }
    }

    /* Feedback criteria for the final project  */ 

    else if (props.activity === 5) {
      if (scale[0] < 50) {
        giveFeedback(content.feedback[0]);
      }
      else if (scale[0] > 250) {
        giveFeedback(content.feedback[1]);
      }
      else if (fontSize[1] < 20) {
        giveFeedback(content.feedback[2]);
      }
      else if (fontSize[2] < 19) {
        giveFeedback(content.feedback[3]);
      }
      else if (fontSize[2] >= fontSize[1]) {
        giveFeedback(content.feedback[4]);
      }
      else if (scale[3] < 160) {
        giveFeedback(content.feedback[5]);
      }
      else if (fill === "no-color") {
        giveFeedback(content.feedback[6]);
      }
      else if (hPadding < 12) {
        giveFeedback(content.feedback[7]);
      }
      else if (vPadding < 5) {
        giveFeedback(content.feedback[8]);
      }
      else if (vPadding > 15) {
        giveFeedback(content.feedback[9]);
      }
      else {
        giveFeedback(content.feedback[10]);
        setCorrectSubmission(true);
      }
    }
  }

  function updateActivity() {
    setFeedback(null);
    setCorrectSubmission(false);
    props.nextStep();
  }

  return (
    <div className="App">
      <Instructions 
        step={step}
        activity={props.activity}
        />
      <Artboard 
        data={props.data}
        step={step}
        fill={fill}
        stroke={stroke}
        scale={scale}
        margin={margin}
        hPadding={hPadding}
        vPadding={vPadding}
        radius={radius}
        shadow={shadow}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontColor={fontColor}
        updateStep={updateStep}
        activity={props.activity}
        displayPrompt={displayPrompt}
        selectItem={selectItem}
        selection={selection}
        />
        {showPrompt ? <div className="prompt">Use these tools to change the styles</div> : null}
      {tool === 'Fill' || tool === 'Color' ? 
        <Fill 
          data={props.data}
          selection={selection}
          onChange={updateFill}
          updateFontColor={updateFontColor}
          activity={props.activity}
          fill={fill}
          fontColor={fontColor} />
      : tool === 'Stroke' ?
        <Stroke 
          onChange={updateStroke}
          activity={props.activity}
          stroke={stroke} />
      : tool === 'Radius' || tool === 'Scale' || tool === 'Margin' || tool === 'Padding' || tool === 'Size' || tool === 'Weight' ?
        <Slider 
          tool={tool}
          selection={selection}
          updateRadius={updateRadius}
          updateScale={updateScale}
          updateMargin={updateMargin}
          updateHPadding={updateHPadding}
          updateVPadding={updateVPadding}
          updateFontSize={updateFontSize}
          updateFontWeight={updateFontWeight}
          radius={radius}
          scale={scale}
          margin={margin}
          vPadding={vPadding}
          hPadding={hPadding}
          fontSize={fontSize}
          fontWeight={fontWeight} />
      : tool === 'Shadow' ?
        <Shadow   
          onChange={updateShadow}
          shadow={shadow} 
          activity={props.activity} />
      : null} 
      {feedback ? 
        <Feedback 
          feedback={feedback}
          dismissFeedback={dismissFeedback} 
          correctSubmission={correctSubmission}
          updateActivity={updateActivity}
          data={content}
        />
      : null}
      <Toolbar
        data={content} 
        activity={props.activity}
        onChange={showTool} 
        submit={submit}
        step={step} 
        selection={selection}
      />
    </div>
  );
}

export default Practice;
