import React, {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import oneOne from '../images/oneOneTitle.svg'
import oneTwo from '../images/oneTwoTitle.svg'
import oneThree from '../images/oneThreeTitle.svg'
import oneFour from '../images/oneFourTitle.svg'
import oneFive from '../images/oneFiveTitle.svg'
import oneSix from '../images/oneSixTitle.svg'
import titleBg from '../images/Intro-bg.svg'
import '../App.css';

const images = [[oneOne, oneTwo, oneThree, oneFour, oneFive, oneSix], null, null]
const Title = (props) => {
  const content = props.data;
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="Title">
      <img className="Title-bg" src={titleBg} alt="Background decoration" />
      <div className="Title-content">
        <div className="Title-info">
          <div className="Title-tag">{content.tag}</div>
          <div className="Title-title">{content.title}</div>
          <div className="Title-subtitle">{content.subtitle}</div>
          <img className="Activity-image2" src={images[props.section][props.activity]} alt="An illustration of an info card" />
        </div>
        <div className="Activity-primary-btn Title-btn" onClick={props.nextStep}>{content.buttonText}</div>
        <Link className="Link-button" to={"/"}><div className="Activity-secondary-btn Title-btn" onClick={props.closeActivity}>Go back</div></Link>
        <Router></Router>
      </div>
    </div> 
  );
};

export default Title;