import React from 'react'
import placeholder from '../images/placeholder.svg'
import hierarchy3 from '../images/hierarchy3.svg'
import hierarchy4 from '../images/hierarchy4.svg'
import hierarchy6 from '../images/hierarchy6.svg'
import hierarchy7 from '../images/hierarchy7.svg'
import type1 from '../images/type1.svg'
import type2 from '../images/type2.svg'
import type3 from '../images/type3.svg'
import '../App.css';

const MultiChoiceStackTextImage = (props) => {
  const content = props.data;
  const hierarchy = [null, null, null, null, null, null, null, null, [hierarchy3, hierarchy4], [hierarchy6, hierarchy7]]
  const typography = [null, [type1, type2, type3], [placeholder, placeholder], null, [placeholder, placeholder, placeholder], null, [placeholder, placeholder, placeholder], [placeholder, placeholder, placeholder], [placeholder, placeholder, placeholder]]
  const typeHierarchy = [null, null, null, null, null, null, null, null, null, null, null, [placeholder, placeholder, placeholder]]
  const buttons = [null, null, [placeholder, placeholder, placeholder], null, [placeholder, placeholder, placeholder, placeholder],]
  const lessons = [null, hierarchy, typography, typeHierarchy, buttons]
  function submitAnswer() {
    props.submitAnswer(props.number)
  }
  return (
    <div className={props.number === props.selection ? "MultiChoice-answer MultiChoiceStack-answer-selected" : "MultiChoice-answer MultiChoiceStack-answer"} onClick={submitAnswer}>
      <div className="MultiChoice-image-container">
        {content.answers 
          ? <p className="MultiChoice-answer-text">
              {content.splitText 
                ? <span>
                    {content.answers[props.number].substring(0, content.splitText[props.number])}
                    <br />
                    {content.answers[props.number].substring(content.splitText[props.number])}
                  </span> 
                : props.answer
              }
            </p>
          : null
        }
        <img className="MultiChoice-text-image" src={lessons[props.activity][props.step][props.number]} alt={content.altText[props.number]} />
      </div>
    </div>  
  );
};

export default MultiChoiceStackTextImage;