import React from 'react';
import LabAlignOptions from './LabAlignOptions.js'
import '../App.css';

function LabAlign(props) {

  return (
    <div className="Fill Fill-dark">
      <div className="Fill-row space-around">
        {props.data.aligns.map((align, index) => {
          return <LabAlignOptions
                    data={props.data}
                    activity={props.activity}
                    index={index}
                    selection={props.selection}
                    align={align}
                    selectedAlign={props.align}
                    updateAlign={props.updateAlign}
                  />
            })}
      </div>
    </div>
  );
}

export default LabAlign;
