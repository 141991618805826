import React from 'react';
import logo from '../images/logo-light.svg'

const Navbar = (props) => {

  return (
    <div className="Path-navbar">
      <div className="Path-navbar-content">
        <img className="Path-navbar-logo" src={logo} alt="Merlin logo" />
        <a href="https://www.learnwithmerlin.com"><div className="Path-navbar-button">Join Waitlist</div></a>
      </div>
    </div>
  );
};

export default Navbar;