import React from 'react'
import '../App.css';

const ActivityTitle = (props) => {
  const content = props.data;
  return (
    <>
      {content.lead ? <p className="Activity-lead">{content.lead}</p> : null}
      {content.titleSubstring 
        ? <div className="Activity-title">
            {content.title.substring(0, content.titleSubstring[0])} 
            <span className="Activity-title-highlight">{content.title.substring(content.titleSubstring[0], content.titleSubstring[1])}</span>
            {content.title.substring(content.titleSubstring[1], content.titleSubstring[2])} 
            <span className="Activity-title-highlight">{content.title.substring(content.titleSubstring[2], content.titleSubstring[3])}</span>
            {content.title.substring(content.titleSubstring[3])}
          </div> 
        : <div className="Activity-title">
            {content.title}
            {content.underline ? <><span className="Activity-title-underline"></span>?</> : null}
          </div>
      }
    </>
  );
};

export default ActivityTitle;