import React from 'react';
import Example from './Example.js';
import '../App.css';

function Instructions(props) {
  const content = [["Let's redesign this button.", "Now style it like this:"], ["Select the bank image with the white background below", "Now give it better size and spacing"], ["test", "test2"], ["Select any of the text blocks below:", "Now update the styling so this page has proper visual hierarchy"], ["Select the button below", "Style it however you like."], ["Select any element on the screen below", "Now fix the page by restyling the elements"]];
  const content2 = [["Tap it to start editing.", null], [null, null], ["test", "test2"], [null, null], [null, "Just make sure it has enough padding and contrast."], [null, null]];
  const lab = [["Hold down this button to see its 'pressed' state"]]
  const lab2 = [[null]]
  return (

    <>
      <div className="Instructions-container">
        <div className={props.step > 0 ? "Instructions-row Instructions-slideout" : "Instructions-row"}>
          <div className="Instructions">
            <div className="Instructions-content">
              <p className="Instructions-content-text">{props.lab ? lab[props.activity][props.step] : content[props.activity][props.step]}</p>
              <p className="Instructions-content-text">{props.lab ? lab2[props.activity][props.step] : content2[props.activity][props.step]}</p>
            </div>
          </div>
          <div className="Instructions">
            <div className="Instructions-content">
              <p className="Instructions-content-text">{content[props.activity][props.step]}</p>
              <p className="Instructions-content-text">{content2[props.activity][props.step]}</p>
              {props.activity === 0 
                ? <div className={props.step > 0 ? "Instructions-content-example" : "Instructions-content-example gone"}>
                    <Example
                      activity={props.activity} />
                  </div>
                : null}
            </div>
          </div>
        </div>
      </div>
    {/*<div className={props.step > 0 ? "Instructions-container Instructions-slideout" : "Instructions-container"}>
      <div className="Instructions-slide">
        <div className="Instructions">
          <div className="Instructions-content">
            <p className="Instructions-content-text">{content[props.activity][props.step]}</p>
            <p className="Instructions-content-text">{content2[props.activity][props.step]}</p>
          </div>
        </div>
      </div>
      <div className={props.step > 0 ? "Instructions-slide Instructions-slidein" : "Instructions-slide hidden"}>
        <div className="Instructions">
          <div className="Instructions-content">
            <p className="Instructions-content-text">{content[props.activity][props.step]}</p>
            <p className="Instructions-content-text">{content2[props.activity][props.step]}</p>
            {props.activity === 0 
              ? <div className="Instructions-content-example">
                  <Example
                    activity={props.activity} />
                </div>
              : null}
          </div>
        </div>
      </div>
            </div>*/}
    </>
  );
}

export default Instructions;
