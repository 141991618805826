import React from 'react';
import '../App.css';


const ArtboardButton = (props) => {
  const radius = props.radius + "px";
  const vPadding = props.vPadding + "px";
  const hPadding = props.hPadding + "px";
  function selectItem() {
    props.selectItem(props.element);
  }
  return (
    <div 
      className={props.selection === props.element ? ("Artboard-project-button Artboard-content-selected " + props.fill + " " + props.shadow) : ("Artboard-project-button Artboard-content-unselected " + props.fill + " " + props.shadow)} 
      style={{borderRadius: radius, 

              paddingTop: vPadding, 
              paddingBottom: vPadding, 
              paddingLeft: hPadding, 
              paddingRight: hPadding}}
      onClick={selectItem}
    >
      Get Started
    </div>
  );
}

export default ArtboardButton;
