import React from 'react';
import '../App.css';
import sparkle from '../icons/sparkleIcon.svg'
import search from '../icons/searchIcon.svg'
import lightning from '../icons/lightingIcon.svg'
import check from '../icons/checkIcon.svg'
import checkCircle from '../icons/checkCircleIcon.svg'
import camera from '../icons/cameraIcon.svg'

const icons = [sparkle, search, lightning, check, checkCircle, camera];

function LabIconOptions(props) {
  function updateIcon() {
    props.updateIcon(props.index)
  }
  return (
    <div className={(props.selectedIcon === props.data.icons[props.index]) ? "selected-dark" : "LabAnimateOption"} onClick={updateIcon}>
      <img src={icons[props.index]} alt="UI icon" />
    </div>
  );
}

export default LabIconOptions;