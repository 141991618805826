import React, {useState, useEffect} from 'react'
import ClickImage from './ClickImage'
import MultiChoice from './MultiChoice'
import StyleChange from './StyleChange'
import Feedback from './Feedback'
import '../App.css';

const Question = (props) => {
  const content = props.data;
  const [selection, setSelection] = useState(null)
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [feedback, setFeedback] = useState(null);
  function submitAnswer(number) {
    setSelection(number);
    setFeedback(content.feedback[number]);
    if (number === content.correctAnswer || number === content.secondCorrectAnswer) {
      setCorrectAnswer(true);
    }
    else {
      setCorrectAnswer(false);
    }
    if (content.storeMessage) {
      props.storeMessage(content.storeMessage, content.answers[number])
    }
  }
  function dismissFeedback() {
    setFeedback(null);
  }
  function updateActivity() {
    setFeedback(null);
    setSelection(null);
    setCorrectAnswer(false);
    props.nextStep();
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="Question">
      {content.questionType === "clickImage"
        ? <ClickImage 
            data={props.data} 
            nextStep={props.nextStep} 
            submitAnswer={submitAnswer}
            activity={props.activity}
            step={props.step}
            feedback={feedback}
          /> 
        : content.questionType === "styleChange" 
        ? <StyleChange 
            data={props.data} 
            activity={props.activity}
            step={props.step}
            nextStep={props.nextStep} 
            submitAnswer={submitAnswer}
            selection={selection}
          /> 
        : <MultiChoice 
            data={props.data} 
            activity={props.activity}
            step={props.step}
            nextStep={props.nextStep} 
            submitAnswer={submitAnswer}
            selection={selection}
          /> 
      }
      {feedback 
        ? <Feedback 
            correctSubmission={correctAnswer}
            feedback={feedback}
            dismissFeedback={dismissFeedback}
            updateActivity={updateActivity}
            data={props.data}
          />
        : null
      }
    </div>
  );
};

export default Question;