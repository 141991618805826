import React from 'react';
import LabStrokeColor from './LabStrokeColor.js'
import '../App.css';

function LabStroke(props) {
  function updateStroke(newStroke) {
    props.onChange(newStroke)
  }

  return (
    <div className="Fill">
      <div className="Fill-row">
        {props.data.strokes1.map((strokeColor, index) => {
          return <LabStrokeColor
                    strokeColor={strokeColor}
                    updateStroke={updateStroke}
                    stroke={props.stroke}
                  />
            })}
      </div>
      <div className="Fill-row">
        {props.data.strokes2.map((strokeColor, index) => {
          return <LabStrokeColor
                    strokeColor={strokeColor}
                    updateStroke={updateStroke}
                    stroke={props.stroke}
                  />
            })}
          </div>
    </div>
  );
}

export default LabStroke;
