import React from 'react';
import '../App.css';
import left from '../icons/alignLeftIcon.svg'
import center from '../icons/alignCenterIcon.svg'
import right from '../icons/alignRightIcon.svg'

const aligns = [left, center, right];

function LabAlignOptions(props) {
  function updateAlign() {
    props.updateAlign(props.data.aligns[props.index])
  }
  return (
    <div className={(props.selectedAlign === props.data.aligns[props.index]) ? "selected-dark" : "LabAnimateOption"} onClick={updateAlign}>
      <div className="centered">
        <img src={aligns[props.index]} alt="UI icon" />
      </div>
    </div>
  );
}

export default LabAlignOptions;