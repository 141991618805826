
// add useContext
/* temporarily commenting this out
import React, {useContext} from 'react';
import {firebaseAuth} from '../provider/AuthProvider';
*/
import React from 'react'
import TempHome from './TempHome'

const Signin = () => {

/* temporarily commenting this out for now 
  const {handleSignin, inputs, setInputs, errors} = useContext(firebaseAuth)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('handleSubmit')
    handleSignin()
    
  }
  const handleChange = e => {
    const {name, value} = e.target
    console.log(inputs)
    setInputs(prev => ({...prev, [name]: value}))
  }
*/
  return (
    <>
      {/*<form onSubmit={handleSubmit}>
        Signin
        <input onChange={handleChange} name="email" placeholder='email' value={inputs.email} />
        <input onChange={handleChange} name="password" placeholder='password' value={inputs.password} />
        <button>signin</button>
        {errors.length > 0 ? errors.map(error => <p style={{color: 'red'}}>{error}</p> ) : null}
      </form>*/}
      <TempHome />
    </>
  );
};

export default Signin;