import React from 'react';
import slash from '../images/slash.svg';
import '../App.css';

function LabFillColor(props) {
  function updateColor() {
    if (props.tool === "Color") {
      props.updateFontColor(props.selection, props.hex)
    }
    else {
      props.updateFill(props.color)
    }
  }
  let colorIcon = (props.color === "no-color") ? "no-color-icon" : (props.color === "white") ? "white-icon" : props.color;
  return (
    <div className={(props.color === props.fill && props.tool !== "Color") || (props.fontColor === props.hex) ? "selected" : null}>
      <div className={"Color " + colorIcon} onClick={updateColor}>
        {props.color === "no-color" ? <img src={slash} alt="" /> : null}
      </div>
    </div>
  );
}

export default LabFillColor;
