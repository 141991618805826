import React from 'react';
import StrokeColor from './StrokeColor.js'
import '../App.css';

function Stroke(props) {
  function updateStroke(newStroke) {
    props.onChange(newStroke)
  }
  const strokes1 = (props.activity === "activity2") ? ["no-border", "white-border", "gray1-border", "gray2-border", "gray3-border", "black-border", "pink-border"] : ["no-border", "white-border", "gray1-border", "gray2-border", "gray3-border", "black-border", "green2-border"];
  const strokes2 = (props.activity === "activity2") ? ["purple0-border", "purple1-border", "purple-border", "blue-border", "green3-border", "yellow-border", "orange-border"] : ["yellow-border", "orange-border", "red-border", "pink-border", "purple-border", "blue-border", "green1-border"];
  
  return (
    <div className="Fill">
      <div className="Fill-row">
        {strokes1.map((strokeColor, index) => {
          return <StrokeColor
                    strokeColor={strokeColor}
                    updateStroke={updateStroke}
                    stroke={props.stroke}
                  />
            })}
      </div>
      <div className="Fill-row">
        {strokes2.map((strokeColor, index) => {
          return <StrokeColor
                    strokeColor={strokeColor}
                    updateStroke={updateStroke}
                    stroke={props.stroke}
                  />
            })}
          </div>
    </div>
  );
}

export default Stroke;
