import React from 'react';
import FillColor from './FillColor.js'
import '../App.css';


function Fill(props) {
  function updateFill(newFill) {
    props.onChange(newFill)
  }
  const colors1 = (props.activity === "activity2") ? ["no-color", "white", "gray1", "gray2", "gray3", "black", "pink"] : ["no-color", "white", "gray1", "gray2", "gray3", "black", "green2"];
  const colors2 = (props.activity === "activity2") ? ["purple0", "purple1", "purple", "blue", "green3", "yellow", "orange"] : ["yellow", "orange", "red", "pink", "purple", "blue", "green1"];
  const hexes1 = ["rgba(0,0,0,0)", "#fff", "#c5cfd8", "#7B8895", "#4E5C6A", "#000", "#3C7669"]
  const hexes2 = ["#F0C44C", "#DB874A", "#C4505E", "#AE46CA", "#6440F4", "#4688D3", "#51AB9F"]
  return (
    <div className="Fill">
      <div className="Fill-row">
        {colors1.map((color, index) => {
          return <FillColor
                    activity={props.activity}
                    selection={props.selection}
                    color={color}
                    fontColor={props.fontColor[props.selection]}
                    fill={props.fill}
                    updateFill={updateFill}
                    updateFontColor={props.updateFontColor}
                    hex={hexes1[index]}
                  />
            })}
      </div>
      <div className="Fill-row">
        {colors2.map((color, index) => {
          return <FillColor
                    activity={props.activity}
                    selection={props.selection}
                    color={color}
                    fontColor={props.fontColor[props.selection]}
                    fill={props.fill}
                    updateFill={updateFill}
                    updateFontColor={props.updateFontColor}
                    hex={hexes2[index]}
                  />
            })}
          </div>
    </div>
  );
}

export default Fill;
