import React, {useState, useEffect} from 'react'
import LabProgress from './LabProgress'
import Title from '../components/Title'
import LabStatement from './LabStatement'
import Question from '../components/Question'
import LabPractice from './LabPractice'
import Demo from '../components/Demo'
import Pairs from '../components/Pairs'
import Carousel from '../components/Carousel'
import LabCongrats from './LabCongrats'
import ReactGA from 'react-ga'
import '../App.css';

const LabActivity = (props) => {
  const [step, setStep] = useState(0);
  const [activity, setActivity] = useState(true);


  // Moved the 'feedback' state up one level for Practice; need to move it up for other types of activities
  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  function nextStep() {
    if (props.activity === 0) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lab',
          action: 'Started lab 1'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lab',
          action: 'Completed lab 1'
        });
      }
    }
    else if (props.activity === 1) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lab',
          action: 'Started lab 2'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lab',
          action: 'Completed lab 2'
        });
      }
    }
    else if (props.activity === 2) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lab',
          action: 'Started lab 3'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lab',
          action: 'Completed lab 3'
        });
      }
    }
    else if (props.activity === 3) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lab',
          action: 'Started lab 4'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lab',
          action: 'Completed lesson 4'
        });
      }
    }
    else if (props.activity === 4) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lab',
          action: 'Started lesson 5'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lab',
          action: 'Completed lab 5'
        });
      }
    }
    else if (props.activity === 5) {
      if (step === 0) {
          ReactGA.event({
          category: 'Lab',
          action: 'Started lab 6'
        });
      }
      else if (props.data[step].finalActivity) {
        ReactGA.event({
          category: 'Lab',
          action: 'Completed lab 6'
        });
      }
    }
    setStep(step + 1);
    setActivity(false);
    setTimeout(resetActivity, 10);
  }

  function goBack() {
    setFeedback(false);
    setStep(step - 1);
    setActivity(false);
    setTimeout(resetActivity, 10);
  }

  function updateProgress() {
    props.updateProgress(props.section, props.activity)
  }

  function submit() {
    setFeedback(true);
  }

  function giveFeedback(newFeedback) {
    setFeedback(newFeedback);
  }

  function dismissFeedback() {
    setFeedback(null);
  }
  
  function resetActivity() {
    setActivity(true);
  }

  return (
    <div className="Activity">
      <LabProgress 
        activity={props.activity}
        data={props.data} 
        step={step} 
        closeActivity={props.closeActivity}
        goBack={goBack} 
        updateProgress={updateProgress}
      />

      {activity === false 
        // This is to reset the state of each activity b/c it was carrying over when two of the same activity types appeared sequentially
        ? null 
        : step === (props.data.length)
        ? <LabCongrats 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={updateProgress}
            nextStep={nextStep} /> 
        : props.data[step].type === "title" 
        ? <Title 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : props.data[step].type === "statement" 
        ? <LabStatement 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : props.data[step].type === "question" 
        ? <Question 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            storeMessage={props.storeMessage}
            nextStep={nextStep} />
        : props.data[step].type === "practice" 
        ? <LabPractice 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            updateStoredAnimate={props.updateStoredAnimate}
            updateStoredIcon={props.updateStoredIcon}
            closeActivity={props.closeActivity}
            storedMessages={props.storedMessages}
            nextStep={nextStep}
            submit={submit}
            feedback={feedback}
            giveFeedback={giveFeedback}
            dismissFeedback={dismissFeedback} />
        : props.data[step].type === "demo" 
        ? <Demo 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep}
            animation={props.storedAnimation}
            icon={props.storedIcon}
            messages={props.storedMessages} />
        : props.data[step].type === "pairs" 
        ? <Pairs 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : props.data[step].type === "carousel" 
        ? <Carousel 
            section={props.section}
            activity={props.activity}
            data={props.data[step]}
            step={step}
            closeActivity={props.closeActivity}
            nextStep={nextStep} />
        : null
      }
    </div>
  );
};

export default LabActivity;