import React from 'react';
import oneOne from '../images/oneOne.svg'
import oneTwo from '../images/oneTwo.svg'
import oneThree from '../images/oneThree.svg'
import oneFour from '../images/oneFour.svg'
import oneFive from '../images/oneFive.svg'
import oneSix from '../images/oneSix.svg'
import twoOne from '../images/twoOne.svg'
import twoTwo from '../images/twoTwo.svg'
import twoThree from '../images/twoThree.svg'
import twoFour from '../images/twoFour.svg'
import twoSix from '../images/twoSix.svg'
import threeOne from '../images/threeOne.svg'
import threeTwo from '../images/threeTwo.svg'
import threeThree from '../images/threeThree.svg'
import threeFour from '../images/threeFour.svg'
import threeFive from '../images/threeFive.svg'
import threeSix from '../images/threeSix.svg'
import oneTwoB from '../images/oneTwoB.svg'
import oneThreeB from '../images/oneThreeB.svg'
import oneFourB from '../images/oneFourB.svg'
import oneFiveB from '../images/oneFiveB.svg'
import oneSixB from '../images/oneSixB.svg'
import twoOneB from '../images/twoOneB.svg'
import twoTwoB from '../images/twoTwoB.svg'
import twoThreeB from '../images/twoThreeB.svg'
import twoFourB from '../images/twoFourB.svg'
import twoSixB from '../images/twoSixB.svg'
import threeOneB from '../images/threeOneB.svg'
import threeTwoB from '../images/threeTwoB.svg'
import threeThreeB from '../images/threeThreeB.svg'
import threeFourB from '../images/threeFourB.svg'
import threeFiveB from '../images/threeFiveB.svg'
import threeSixB from '../images/threeSixB.svg'
import lock from '../images/lock.svg'
import completed from '../images/completed.svg'

const PathItem = (props) => {
  function openActivity() {
    props.openActivity(props.section, props.number)
  }
  const locked = (props.status === "locked")
  const icons = [[oneOne, oneTwo, oneThree, oneFour, oneFive, oneSix], [twoOne, twoTwo, twoThree, twoFour, threeFive, twoSix], [threeOne, threeTwo, threeThree, threeFour, threeFive, threeSix]]
  const lockedIcons = [[oneOne, oneTwoB, oneThreeB, oneFourB, oneFiveB, oneSixB], [twoOneB, twoTwoB, twoThreeB, twoFourB, threeFiveB, twoSixB], [threeOneB, threeTwoB, threeThreeB, threeFourB, threeFiveB, threeSixB]]
  const tops = [141, 297, 450, 630, 800, 1000]
  const xPosition = [-100, 30, -100, -5, -140, -50]
  const yPosition = [0, 1375, 2620]
  const style = {
    top: (yPosition[props.section] + tops[props.number]) + "px",
    marginLeft: xPosition[props.number] + "px"
  }
  return (
    <div className={props.status === "unlocked" ? "PathItem" : "PathItem"} style={style} onClick={locked ? null : openActivity}>
      {props.status === "locked" 
        ? <img className={props.number === 5 ? "Project-icon" : "PathItem-icon"} src={lock} alt="locked" /> 
        : props.status === "complete" 
        ? <img className={props.number === 5 ? "Project-icon" : "PathItem-icon"} src={completed} alt="completed" /> 
        : null
      }
      <img className="PathItem-activity-icon" src={locked ? lockedIcons[props.section][props.number] : icons[props.section][props.number]} alt="lesson icon" />
      <div className={locked ? "PathItem-locked" : (props.status === "unlocked") ? "PathItem-unlocked": "PathItem-complete"}>
        {props.number === 5 
          ? <p className="PathItem-project">
              <span className="PathItem-project-heading">{props.name.substring(0, 8)}</span>
              <span className="PathItem-project-text">{props.name.substring(8)}</span>
            </p>
          : props.name}
      </div>
    </div>
  );
};

export default PathItem;