import React from 'react'
import MultiChoiceGridAnswer from './MultiChoiceGridAnswer'
import '../App.css';

const MultiChoiceGrid = (props) => {

  const content = props.data;

  return (
    <div className="MultiChoiceGrid">      
      <div className="MultiChoiceGrid-row"> 
        <MultiChoiceGridAnswer 
          answer={content.answers[0]}
          number={0} 
          submitAnswer={props.submitAnswer}
          data={props.data}
          selection={content.questionType === "styleChange" ? props.selection[0] : props.selection}
        />
        <MultiChoiceGridAnswer 
          answer={content.answers[1]}
          number={1} 
          submitAnswer={props.submitAnswer}
          data={props.data}
          selection={content.questionType === "styleChange" ? props.selection[1] : props.selection}
        />
      </div>   
      <div className="MultiChoiceGrid-row">   
        <MultiChoiceGridAnswer 
          answer={content.answers[2]}
          number={2} 
          submitAnswer={props.submitAnswer}
          data={props.data}
          selection={content.questionType === "styleChange" ? props.selection[2] : props.selection}
        />
        <MultiChoiceGridAnswer 
          answer={content.answers[3]}
          number={3} 
          submitAnswer={props.submitAnswer}
          data={props.data}
          selection={content.questionType === "styleChange" ? props.selection[3] : props.selection}
        />
      </div>   
    </div>
  );
};

export default MultiChoiceGrid;