import React, {useState} from 'react'
import '../App.css';
import '../animations.css'
import cursor from '../icons/cursor.svg'
import OutsideAlerter from '../lab/OutSideAlerter'


const DemoInput = (props) => {

  const [clicked, setClicked] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const borderColor = activeState ? "purple-border" : "gray1-border";

  function addActiveState() {
    if (!clicked) {
      setClicked(true);
      setTimeout(props.submit, 4000)
    }
    setActiveState(true)
  }
  function removeActiveState() {
    setActiveState(false);
  }

  return (
    <>
      <div className="Object-container">
        <OutsideAlerter outsideClick={removeActiveState}>
          <div 
            className={"Object m-0 Object-edit-input white r-8 gray2-text justify-left " + borderColor} 
            onClick={addActiveState}
          >
            <img className={activeState ? "blinking" : "hidden"} src={cursor} alt="cursor" />
            <div className={activeState ? "LabArtboardInput-line LabArtboardInput-line-expand" : "LabArtboardInput-line"}></div>
            <p className={activeState ? "overline overline-active" : "overline"}>Email</p>
            
          </div>
        </OutsideAlerter>
      </div>
    </>
  );
}

export default DemoInput;
