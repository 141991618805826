import React, {useState, useEffect} from 'react';
import testGif from '../images/testGif.jpg'
import arrowRight from '../images/arrow-right.svg'
import arrowRetry from '../images/arrow-retry.svg'
import sparkleGreen from '../images/sparkle-green.svg'
import '../App.css';

function Feedback(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [gifLoaded, setGifLoaded] = useState(false);
  const gifImg = new Image();
  gifImg.src = "https://media.giphy.com/media/l4pTfSeH65zpQW7yo/giphy.gif"
  gifImg.onload = () => {
    setGifLoaded(true);
  }
  useEffect(() => {
    const icons = [arrowRight, arrowRetry, sparkleGreen];
    cacheIcons(icons);
  }, []);
  const cacheIcons = async (srcArray) => {
    const promises = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setIsLoading(false);
  }
  return (
    <div className={props.feedback ? "Feedback-container Feedback-entrance" : "Feedback-container Feedback-exit"}>
      <div className={props.data.neutralFeedback ? "Feedback Feedback-neutral" : props.correctSubmission ? "Feedback" : "Feedback Feedback-incorrect2"}>
        <div className="Feedback-message-container">
          <div className="Feedback-message">
            {(props.correctSubmission && !props.data.neutralFeedback) ? <img className="Feedback-message-icon" src={sparkleGreen} alt="sparkles" /> : null}
            {props.feedback === "image" 
              ? <div>
                    {gifLoaded 
                      ? <img className="Feedback-image" src="https://media.giphy.com/media/l4pTfSeH65zpQW7yo/giphy.gif" alt="expressive gif" />
                      : <img className="Feedback-image" src={testGif} alt="distraught guy" />}
                </div> 
              : props.feedback}
          </div>
          {isLoading ? null : null}
        </div>
        <div className="Feedback-button-container" >
          <div className={props.data.neutralFeedback ? "Feedback-button Feedback-button-neutral" : props.correctSubmission ? "Feedback-button" : "Feedback-button Feedback-button-incorrect2"} onClick={(props.correctSubmission || props.data.neutralFeedback) ? props.updateActivity : props.dismissFeedback}>
            <div className="Feedback-button-content">
              {(props.correctSubmission || props.data.neutralFeedback) ? <p className="Feedback-button-content-text">Continue</p> : props.data.type === "practice" ? <p className="Feedback-button-content-text">Got it</p> : <p className="Feedback-button-content-text">Try again</p>}
              <img className="Feedback-button-content-icon" src={(props.correctSubmission || props.data.neutralFeedback) ? arrowRight : arrowRetry} alt="arrow icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedback; 
