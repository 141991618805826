import React from 'react';
import LabPathItem from './LabPathItem'
import Navbar from '../components/Navbar'
import section1 from '../data/lab1.json'
import {
  BrowserRouter as Router,
Link
} from "react-router-dom";

const sections = [section1];

const LabPath = (props) => {
  const sectionClasses = ["LabPath", "Path-section2", "Path-section3"]
  return (
    <div>
      <Navbar />
      {sections.map((section, sectionIndex) => {
        return(
          <>
            <div className="Path-divider">
              <div className="Path-divider-line"></div>
              <div className="Path-divider-outline">
                <div className="Path-divider-content">
                  {"SECTION " + (sectionIndex + 1)}
                </div>
              </div>
              <div className="Path-divider-line Path-divider-line-two"></div>
            </div>
            <div className={sectionClasses[sectionIndex]}>
              {Object.keys(section).map((activity, index) => {
                return  <Link to={"/lab/practice/" + index}>
                          <LabPathItem 
                            name={activity}
                            number={index}
                            openActivity={props.openActivity}
                            section={sectionIndex}
                            status={props.progress[sectionIndex][index]}
                          />
                        </Link>
              })}
            </div>
          </>
        )
      })}
        <Router></Router>
    </div>
  );
};

export default LabPath;