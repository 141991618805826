import React from 'react'

function LabPracticeSubmit(props) {
  const content = props.data;

  if (props.feedback) {
    console.log("test1")
    switch(content.id) {
      case 1.1:
        oneOne();
        break;
      case 1.4:
        oneFour();
        break;
      case 1.5:
        oneFive();
        break;
      case 1.6:
        oneSix();
        break;
      case 2.1:
        twoOne();
        break;
      case 2.2:
        twoTwo();
        break;
      case 2.5:
        twoFive();
        break;
      case 2.6:
        twoSix();
        break;
      case 3.3:
        threeThree();
        break;
      case 3.4:
        threeFour();
        break;
      default:
        oneFive();
    }
  }

  function oneOne() {
    if (props.fill !== 'purple') {
      props.giveFeedback(content.feedback[0]);
    }
    else if (props.stroke !== 'no-border' && props.stroke !== 'purple-border') {
      props.giveFeedback(content.feedback[1]);
    }
    else if (props.radius < 28) {
      props.giveFeedback(content.feedback[2]);
    }
    else if (props.shadow !== 'purple-shadow') {
      props.giveFeedback(content.feedback[3]);
    }
    else {
      props.giveFeedback(content.feedback[4]);
      props.setCorrectSubmission(true);
    }
  }

  function oneFour() {
    if (props.fill !== 'purple-dark') {
      props.giveFeedback(content.feedback[0]);
    }
    else if (props.stroke !== 'no-border' && props.stroke !== 'purple-dark-border') {
      props.giveFeedback(content.feedback[1]);
    }
    else if (props.shadow === 'purple-shadow') {
      props.giveFeedback(content.feedback[2]);
    }
    else {
      props.giveFeedback(content.feedback[3]);
      props.setCorrectSubmission(true);
    }
  }

  function oneFive() {
    if (props.animate === null) {
      props.giveFeedback(content.feedback[0]);
    }
    else {
      props.giveFeedback(content.feedback[1]);
      props.setCorrectSubmission(true);
    }
  }

  function oneSix() {
    if (props.icon === null) {
      props.giveFeedback(content.feedback[0]);
    }
    else if (props.icon === 1 || props.icon === 5) {
      props.giveFeedback(content.feedback[1]);
    }
    else {
      props.giveFeedback(content.feedback[2]);
      props.setCorrectSubmission(true);
    }
  }

  function twoOne() {
    if (props.fill !== 'white') {
      props.giveFeedback(content.feedback[0]);
    }
    else if (props.stroke !== 'gray1-border') {
      props.giveFeedback(content.feedback[1]);
    }
    else if (props.radius < 6) {
      props.giveFeedback(content.feedback[2]);
    }
    else if (props.radius > 10) {
      props.giveFeedback(content.feedback[3]);
    }
    else if (props.align !== 'justify-left') {
      props.giveFeedback(content.feedback[4]);
    }
    else {
      props.giveFeedback(content.feedback[5]);
      props.setCorrectSubmission(true);
    }
  }

  function twoTwo() {
    if (props.stroke !== 'purple-border') {
      props.giveFeedback(content.feedback[0]);
    }
    else {
      props.giveFeedback(content.feedback[1]);
      props.setCorrectSubmission(true);
    }
  }

  function twoFive() {
    if (props.fontColor[0] !== '#6440F4') {
      props.giveFeedback(content.feedback[0]);
    }
    else if (props.fontSize[0] !== "13") {
      props.giveFeedback(content.feedback[1]);
    }
    else {
      props.giveFeedback(content.feedback[2]);
      props.setCorrectSubmission(true);
    }
  }

  function twoSix() {
    if (props.y !== '-25') {
      props.giveFeedback(content.feedback[0]);
    }
    else {
      props.giveFeedback(content.feedback[1]);
      props.setCorrectSubmission(true);
    }
  }

  function threeThree() {
    if (props.fontColor[0] !== '#C4505E') {
      props.giveFeedback(content.feedback[0]);
    }
    else if (props.fontSize[0] !== "13") {
      props.giveFeedback(content.feedback[1]);
    }
    else {
      props.giveFeedback(content.feedback[2]);
      props.setCorrectSubmission(true);
    }
  }

  function threeFour() {
    if (props.stroke !== 'red-border') {
      props.giveFeedback(content.feedback[0]);
    }
    else {
      props.giveFeedback(content.feedback[1]);
      props.setCorrectSubmission(true);
    }
  }

  return <></>

}

export default LabPracticeSubmit;


