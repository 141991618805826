import React from 'react'
import ActivityTitle from './ActivityTitle'
import ActivitySubtitle from './ActivitySubtitle'
import MultiChoiceGrid from './MultiChoiceGrid'
import MultiChoiceStack from './MultiChoiceStack'
import ToggleImage from './ToggleImage'
import hierarchy7 from '../images/hierarchy7.png'
import hierarchy9 from '../images/hierarchy9.png'
import btnPadding from '../images/btnPadding.svg'
import typoHierSubheading from '../images/typoHierSubheading.svg'
import errorExample from "../images/errorExample.svg"
import '../App.css';

const images = [null, hierarchy9, hierarchy7, typoHierSubheading, btnPadding, errorExample];

const MultiChoice = (props) => {
  const content = props.data;
  return (
    <div className="Question">      
      <div className="Activity-content">
        <div className="Activity-info">
          <ActivityTitle data={props.data} />
          {content.questionType === "toggleImage" 
            ? <ToggleImage />
            : null
          }
          <ActivitySubtitle data={props.data} />
          {content.image 
            ? <img className="Activity-image" src={images[content.image]} alt={content.alt} />
            : null
          }
          {content.questionType === "multiChoiceGrid" 
            ? <MultiChoiceGrid 
                data={props.data} 
                submitAnswer={props.submitAnswer}
                selection={props.selection}
              />
            : <MultiChoiceStack 
                data={props.data} 
                step={props.step} 
                selection={props.selection}
                activity={props.activity} 
                submitAnswer={props.submitAnswer}/>
          }
        </div>
      </div>
    </div>
  );
};

export default MultiChoice;