import React from 'react';
import LabIconOptions from './LabIconOptions.js'
import '../App.css';

function LabIcon(props) {

  return (
    <div className="Fill Fill-dark">
      <div className="Fill-row space-around">
        {props.data.icons.map((icon, index) => {
          return <LabIconOptions
                    data={props.data}
                    activity={props.activity}
                    index={index}
                    selection={props.selection}
                    icon={icon}
                    selectedIcon={props.icon}
                    updateIcon={props.updateIcon}
                  />
            })}
      </div>
    </div>
  );
}

export default LabIcon;
